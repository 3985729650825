import './../actions.scss';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextareaControl } from '@controls/index';
import { IBaseAction } from '@models/actions/IBaseAction';
import { ActivityBaseActionExecutor } from '@utils/actions/ActivityBaseActionExecutor';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@atoms/Modal/Modal';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';

export class RollbackDelegateExecutor extends ActivityBaseActionExecutor {
    private _modalProps?: IDelegateProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            activityId: getActivityIdFromCompositeId(objId),
            actionKey: action.key,
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <DelegateModal {...this._modalProps} /> : <></>;
    };
}

interface IDelegateProps {
    activityId: string;
    actionKey: string;
    title: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const DelegateModal: React.FC<IDelegateProps> = (props: IDelegateProps) => {
    const [comment, setComment] = useState<string>();
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const serv = new ActivityExecutionService();

    const onSubmit = (form: IDelegateFormData) => {
        setLoading(true);

        return serv
            .saveRollbackDelegate(props.actionKey, props.activityId, form?.comment)
            .then((res) => {
                setLoading(false);

                props.completeHandler && props.completeHandler(true);
            })
            .catch((err) => {
                setErrorText(err);
            })
            .finally(() => setLoading(false));
    };

    const formState = useForm<IDelegateFormData>({
        mode: 'onBlur', // "onChange"
    });

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            <TextareaControl
                label="Комментарий"
                readOnly={false}
                required={true}
                rules={{ required: 'Обязательное поле' }}
                name="comment"
                rows={6}
                formState={formState.formState}
                control={formState.control}
                onValueChange={(val: any) => {
                    setComment(val);
                }}
            />
        </ModalContainer>
    );
};

interface IDelegateFormData {
    comment: string;
}
