import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IBaseAction } from '@models/actions/IBaseAction';
import { LocationTypes } from '@models/actions/LocationTypes';
import { IActionService } from './IActionService';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import { DocActionsExecFactory } from '@utils/actions/DocActionsExecFactory';
import { ActionAccessibleCheckResult } from '@models/actions/ActionAccessibleCheckResult';

export class DocumentActionsService implements IActionService<IBaseAction> {
    public getActionExecutor(act: IBaseAction): IActionExecutor {
        return DocActionsExecFactory.GetActionExecutor(act)!;
    }

    //Получает действия с поисковой папки
    async fetchActions(docId: string): Promise<AxiosResponse<IBaseAction[]>> {
        return DocumentActionsService.fetchActionsByLocation(docId, LocationTypes.SearchResult);
    }

    //Получает групповые действия с поисковой папки
    async fetchGroupActions(docIds: string): Promise<AxiosResponse<IBaseAction[]>> {
        return DocumentActionsService.fetchGroupActionsByLocation(docIds, LocationTypes.SearchResult);
    }

    //Получает действия с карточки документа
    async fetchActionsDocumentView(docId: string): Promise<AxiosResponse<IBaseAction[]>> {
        return DocumentActionsService.fetchActionsByLocation(docId, LocationTypes.DocumentView);
    }

    //Получает действия с заголовка карточки документа
    async fetchActionsDocumentTitleBar(
        docId: string,
        openDocumentGuid: string | undefined,
    ): Promise<AxiosResponse<IBaseAction[]>> {
        return DocumentActionsService.fetchActionsByLocation(
            docId,
            LocationTypes.DocumentTitleBar,
            null,
            openDocumentGuid,
        );
    }

    async checkActionAccessibility(
        docId: string | undefined,
        actionKey: string,
    ): Promise<AxiosResponse<ActionAccessibleCheckResult>> {
        return $api.get<ActionAccessibleCheckResult>(`document/checkActionAccessibility/${actionKey}/${docId ?? ''}`);
    }

    async checkBaseActionAccessibility(
        docId: string | undefined,
        actionKey: string,
    ): Promise<AxiosResponse<ActionAccessibleCheckResult>> {
        return $api.get<ActionAccessibleCheckResult>(
            `document/checkBaseActionAccessibility/${actionKey}/${docId ?? ''}`,
        );
    }

    async checkActionGroupAccessibility(
        docIds: string | undefined,
        actionKey: string,
    ): Promise<AxiosResponse<ActionAccessibleCheckResult>> {
        return $api.get<ActionAccessibleCheckResult>(
            `document/checkActionGroupAccessibility/${actionKey}/${docIds ?? ''}`,
        );
    }

    static async fetchActionsByLocation(
        docId: string | undefined,
        location: number,
        searchType?: string | null,
        openDocumentGuid: string | undefined = undefined,
    ): Promise<AxiosResponse<IBaseAction[]>> {
        return $api.get<IBaseAction[]>(`Document/actions/${docId ?? ''}`, {
            params: {
                location: location,
                searchType: searchType,
                openDocumentGuid: openDocumentGuid,
            },
        });
    }

    static async fetchGroupActionsByLocation(
        docIds: string | undefined,
        location: number,
        searchType?: string | null,
    ): Promise<AxiosResponse<IBaseAction[]>> {
        return $api.get<IBaseAction[]>(
            `Document/groupActions/${docIds ?? ''}?location=${location}&searchType=${searchType ?? ''}`,
        );
    }
}
