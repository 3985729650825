import React, { FC, useRef } from 'react';
import './DocumentTabRoutes.scss';

import DevExpressTreeListDynamic from '@atoms/DevExpress/TreeList/DevExpressTreeListDynamic';
import Box from '@atoms/Box';
import { DocumentRoutesService } from '@services/DocumentRoutesService';
import { DataGridStateService } from '@services/DataGridStateService';
import { FilesService } from '@services/FilesService';
import { ProcessActionsService } from '@services/actions/ProcessActionsService';
import { DropdownContainerContext } from '@/context/Contexts';

export interface IDocumentTabRoutesProps {
    /** ИД документа */
    docId: string;
}

const DocumentTabRoutes: FC<IDocumentTabRoutesProps> = ({ docId }: IDocumentTabRoutesProps) => {
    const serviceGrid = new DocumentRoutesService();
    const stateService = new DataGridStateService();
    const filesService = new FilesService();
    const actionsService = new ProcessActionsService();
    const tabRef = useRef<HTMLDivElement>(null);

    return (
        <DropdownContainerContext.Provider value={tabRef}>
            <div className="routes-tab" ref={tabRef}>
                <Box header="Маршруты">
                    <div className="routes-table">
                        <DevExpressTreeListDynamic
                            key="grid_routes"
                            stateService={stateService}
                            dataKey={docId}
                            gridKey="routes"
                            service={serviceGrid}
                            filesService={filesService}
                            actionService={actionsService}
                        />
                    </div>
                </Box>
            </div>
        </DropdownContainerContext.Provider>
    );
};

export default DocumentTabRoutes;
