import React, { FC, useEffect, useState } from 'react';
import { RouteNames } from '@/router';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { useActions } from '@hooks/useActions';
import './Navbar.scss';
import logo from '@images/logo.svg';
import Search from '@atoms/Search';
import Button from '@atoms/Button';
import Menu from '@atoms/Menu';
import {
    MdClose,
    MdDescription,
    MdEditNotifications,
    MdGroup,
    MdGroupAdd,
    MdLogout,
    MdOutlineExpandLess,
    MdOutlineExpandMore,
    MdOutlineNotifications,
    MdSettings,
    MdSupervisorAccount,
    MdSupportAgent,
} from 'react-icons/md';
import { IListElement, IOption } from '@/types';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IHistorySearchItem } from '@models/search/IHistorySearchItem';
import SupportModal from '@atoms/SupportModal/SupportModal';
import UserPanel from '../UserPanel/UserPanel';
import List from '@atoms/Menu/List';
import VersionPanel from '../VersionPanel/VersionPanel';
import Avatar from '@atoms/Avatar';
import Modal from '@/components/atoms/Modal/Modal';
import { UserService } from '@/services/UserService';
import { classnames } from '@/utils/classnames';
import Select from '@/components/atoms/Select/Select';
import { IAddressBookItem } from '@/models/addressbook/IAddressBookItem';
import Preloader from '@/components/atoms/Preloader/Preloader';
import { jwtDecode } from 'jwt-decode';

const Navbar: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showSupportModal, setShowSupportModal] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const user = useTypedSelector((state) => state.auth.user);
    const userAvatar = useTypedSelector((state) => state.userAvatar);
    const { getUserAvatar, logout, searchDocumentsGlobal } = useActions();

    const [isOrgEditMode, setIsOrgEditMode] = useState<boolean>();
    const [isSavingDepartment, setIsSavingDepartment] = useState<boolean>();
    const [selectedDep, setSelectedDep] = useState<IOption | undefined>();
    const [departmentsList, setDepartmentsList] = useState<IOption[]>([]);
    const { updateUserDepartment } = useActions();

    useEffect(() => {
        getUserAvatar();
    }, []);

    useEffect(() => {
        if (isOrgEditMode) {
            UserService.getDepsToSelect().then((deps) => {
                let depOptions = deps.data.map((x: IAddressBookItem) => ({
                    label: x.name,
                    value: x.key,
                })) as IOption[];
                setDepartmentsList(depOptions);
                setSelectedDep(depOptions.find((x) => x.value == user.departmentId));
            });
        }
    }, [isOrgEditMode]);

    const [showMenu, setShowMenu] = useState<boolean>(false);

    const settingsItem: IListElement = {
        value: '101',
        label: (
            <div className={'settings-item'}>
                <MdSettings size="24" />
                <span>Настройки</span>
            </div>
        ),
        handler: () => {
            navigate(RouteNames.SETTINGS);
        },
    };
    const usersActivationItem: IListElement = {
        value: '102',
        label: (
            <div className={'settings-item'}>
                <MdGroupAdd size="24" />
                <span>Активация пользователей</span>
            </div>
        ),
        handler: () => {
            navigate(RouteNames.USERS_ACTIVATION);
        },
    };
    const setMailDeputyItem: IListElement = {
        value: '103',
        label: (
            <div className={'settings-item'}>
                <MdSupervisorAccount size="24" />
                <span>Замещение сотрудников и уведомления</span>
            </div>
        ),
        handler: () => {
            navigate(RouteNames.REDIRECTS_AND_REPLACEMENTS);
        },
    };
    const setAssistantSimple: IListElement = {
        value: '107',
        label: (
            <div className={'settings-item'}>
                <MdSupervisorAccount size="24" />
                <span>Назначить заместителя</span>
            </div>
        ),
        handler: () => {
            navigate(RouteNames.SET_ASSISTANT_SIMPLE);
        },
    };
    const viewAccessGroupsItem: IListElement = {
        value: '104',
        label: (
            <div className={'settings-item'}>
                <MdGroup size="24" />
                <span>Роли</span>
            </div>
        ),
        handler: () => {
            navigate(RouteNames.ROLES);
        },
    };
    const selfEditNotificationFilteringItem: IListElement = {
        value: '105',
        label: (
            <div className={'settings-item'}>
                <MdEditNotifications size="24" />
                <span>Настройка уведомлений</span>
            </div>
        ),
        handler: () => {
            navigate(RouteNames.NOTIFICATIONSETTINGS);
        },
    };

    const exitItem: IListElement = {
        value: '106',
        label: (
            <div className={'settings-item settings-item--logout'}>
                <MdLogout size="24" />
                <span>Выход из профиля</span>
            </div>
        ),
        handler: async () => {
            const token = localStorage.getItem('frontAccessToken');
            const decoded = jwtDecode(token!);
            if ((decoded as any).signInMethod === 'SAML') {
                navigate(RouteNames.ADFS_SAML_SSO_SIGN_OUT_REDIRECT, {
                    replace: true,
                });
            } else {
                logout();
            }
        },
        separated: true,
        separatedBottom: true,
    };

    const _listMenu: IListElement[] = [];

    if (user.canView.settings) {
        _listMenu.push(settingsItem);
    }

    if (user.canView.usersActivation) {
        _listMenu.push(usersActivationItem);
    }

    if (user.canView.mailDeputy) {
        _listMenu.push(setMailDeputyItem);
    }

    if (user.canView.accessGroups) {
        _listMenu.push(viewAccessGroupsItem);
    }

    if (user.canView.notificationFiltering) {
        _listMenu.push(selfEditNotificationFilteringItem);
    }

    if (user.canView.setAssistantSimple) {
        _listMenu.push(setAssistantSimple);
    }

    if (user.canView.signOut) {
        _listMenu.push(exitItem);
    }

    const onKeyUp = (e: any) => {
        if (e.keyCode == 13) {
            let searchString = e.target.value;
            doSearchByString(searchString);
        }
    };

    const doSearchByString = (searchString: any) => {
        if (location.pathname !== RouteNames.SEARCH) {
            navigate(`${RouteNames.SEARCH}?text=` + searchString);
        } else {
            setSearchParams({ text: searchString });
            searchDocumentsGlobal({ text: searchString, requireTotalCount: true });
        }
    };

    const onHistorySearchSelected = (historyItem: IHistorySearchItem) => {
        doSearchByString(historyItem.text);
    };

    const onSupportToggle = () => {
        setShowSupportModal(!showSupportModal);
    };

    const getUserMenu = (list: IListElement[]) => {
        return (
            <div className="user-menu-dropdown">
                <UserPanel
                    onOrgChange={() => {
                        setIsOrgEditMode(true);
                    }}
                />
                <List list={list} />
                <VersionPanel />
            </div>
        );
    };

    return (
        <div className="rf-navbar">
            <Link to="/">
                {' '}
                <div className="logo-button">
                    <img src={logo} />
                </div>
            </Link>
            <div className="controls">
                <div className="search">
                    <Search width={350} onKeyUp={onKeyUp} onHistorySearchSelected={onHistorySearchSelected} />
                </div>
                <div className="items">
                    {/*{user.canView.adminPanel && (
                        <Button
                            buttonType="round"
                            textColor="neutral"
                            title="Администрирование"
                            size="m"
                            startAdornment={<MdSettings size="32" />}
                        ></Button>
                    )}*/}
                    {user.canView.documentation && (
                        <Button
                            buttonType="round"
                            textColor="neutral"
                            title="Документация"
                            size="m"
                            startAdornment={<MdDescription size="32" />}
                            onClick={() => {
                                navigate(RouteNames.DOCUMENTATION);
                            }}
                        ></Button>
                    )}
                    {user.canView.support && (
                        <Button
                            buttonType="round"
                            title="Техническая поддержка"
                            textColor="neutral"
                            size="m"
                            startAdornment={<MdSupportAgent size="32" />}
                            onClick={onSupportToggle}
                        ></Button>
                    )}
                    <Button
                        buttonType="round"
                        title="Уведомления"
                        textColor="neutral"
                        size="m"
                        startAdornment={<MdOutlineNotifications size="32" />}
                        onClick={() => {
                            navigate(RouteNames.NOTIFICATION);
                        }}
                    ></Button>
                </div>
                {showSupportModal && <SupportModal onCloseModal={onSupportToggle} />}
                <div className="user">
                    <div>
                        <Menu
                            content={getUserMenu(_listMenu)}
                            position="bottom-end"
                            onToggleMenu={(show) => {
                                setShowMenu(show);
                            }}
                        >
                            <div className="user-menu">
                                <Button
                                    buttonType="round"
                                    textColor="neutral"
                                    size="m"
                                    className="user-menu__button"
                                    startAdornment={
                                        userAvatar.photoContent ? (
                                            <Avatar
                                                photo={`data:image/png;base64,${userAvatar.photoContent}`}
                                                firstName={user.firstName}
                                                lastName={user.lastName}
                                                size="s"
                                            />
                                        ) : (
                                            <></>
                                        )
                                    }
                                    endAdornment={
                                        showMenu ? (
                                            <MdOutlineExpandLess size={'20px'} />
                                        ) : (
                                            <MdOutlineExpandMore size={'20px'} />
                                        )
                                    }
                                >
                                    {user.name}
                                </Button>
                            </div>
                        </Menu>
                    </div>
                </div>
            </div>

            {isOrgEditMode &&
                (isSavingDepartment ? (
                    <Preloader size="xl" />
                ) : (
                    <Modal
                        size="m"
                        footer={
                            <Button
                                disabled={!selectedDep}
                                onClick={async () => {
                                    if (selectedDep) {
                                        setIsSavingDepartment(true);
                                        await UserService.setUserDepartment(selectedDep.value);
                                        updateUserDepartment(selectedDep.value, selectedDep.label);
                                        setIsOrgEditMode(false);
                                        setIsSavingDepartment(false);
                                    }
                                }}
                            >
                                OK
                            </Button>
                        }
                        header={
                            <div className={classnames('box')}>
                                <div>
                                    <div>Выбор организации</div>
                                </div>
                                <div className={classnames('left')}>
                                    <Button
                                        buttonType="text"
                                        textColor="neutral"
                                        size="xs"
                                        aria-label="Закрыть окно"
                                        onClick={() => {
                                            setIsOrgEditMode(false);
                                        }}
                                        startAdornment={<MdClose size="24" />}
                                    />
                                </div>
                            </div>
                        }
                    >
                        <Select
                            placeholder="Выберите организацию"
                            readOnly
                            values={selectedDep ? [selectedDep] : undefined}
                            options={departmentsList}
                            preloader={departmentsList.length == 0}
                            onChange={(e) => {
                                setSelectedDep(e?.length > 0 ? e[0] : undefined);
                            }}
                        />
                    </Modal>
                ))}
        </div>
    );
};

export default Navbar;
