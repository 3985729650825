import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IRouteMapActionResult } from '@models/document/routeMapActionsExecutors/IRouteMapActionModel';

export class RouteMapExecutionService {
    add(
        actionKey: string,
        activityKey: string,
        processKey: string,
        documentKey: string,
        result: IRouteMapActionResult,
    ): Promise<AxiosResponse> {
        return $api.post(`routeMapExecution/add/${actionKey}/${documentKey}/`, result, {
            params: { processKey, activityKey },
        });
    }

    edit(
        actionKey: string,
        activityKey: string,
        processKey: string,
        documentKey: string,
        result: IRouteMapActionResult,
    ): Promise<AxiosResponse> {
        return $api.post(`routeMapExecution/edit/${actionKey}/${documentKey}/${processKey}/${activityKey}`, result);
    }

    remove(actionKey: string, activityKey: string, processKey: string, documentKey: string): Promise<AxiosResponse> {
        return $api.post(`routeMapExecution/remove/${actionKey}/${documentKey}/${processKey}/${activityKey}`);
    }
}
