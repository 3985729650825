import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { classnames } from '@utils/classnames';

export interface DeleteRowMultiButtonProps {
    onClick: (e: React.MouseEvent) => void;
}

export interface IDeleteRowMultiButtonRefActions {
    toggleVisibility: (value: boolean) => void;
}

const DeleteRowMultiButton = forwardRef<IDeleteRowMultiButtonRefActions, DeleteRowMultiButtonProps>(
    ({ onClick }: DeleteRowMultiButtonProps, ref) => {
        const [visible, setVisible] = useState<boolean>(false);

        useImperativeHandle(ref, () => ({
            toggleVisibility: (value: boolean) => {
                setVisible(value);
            },
        }));

        return (
            <a
                href="#"
                className={classnames('dx-link', 'dx-link-delete', 'dx-icon-trash', 'dx-link-icon', {
                    'dx-state-invisible': !visible,
                })}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClick(e);
                }}
                title="Удалить выбранные строки"
                aria-label="Удалить выбранные строки"
            />
        );
    },
);

export default DeleteRowMultiButton;
