import './../actions.scss';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AbookpickerControl } from '@controls/index';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import { ActivityBaseActionExecutor } from '@utils/actions/ActivityBaseActionExecutor';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import { IAbookOptions } from '@models/Forms/IAbookOptions';
import { IFormValues } from '@models/Forms/IForms';
import { getABookFormValues } from '@utils/abookUtils';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@atoms/Modal/Modal';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';

export class ChangePerformerExecutor extends ActivityBaseActionExecutor {
    private _modalProps?: IChangePerformerProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let activityAction = action as IActivityAction;
        this._modalProps = {
            activityId: getActivityIdFromCompositeId(objId),
            docId: activityAction?.docId ?? '-1',
            actionKey: action.key,
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <ChangePerformerModal {...this._modalProps} /> : <></>;
    };
}

interface IChangePerformerProps {
    activityId: string;
    docId: string;
    actionKey: string;
    title: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const ChangePerformerModal: React.FC<IChangePerformerProps> = (props: IChangePerformerProps) => {
    const [executor, setExecutor] = useState<IAddressBookData>();
    const [abookOptions, setAbookOptions] = useState<IAbookOptions>();
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [formValues, setFormValues] = useState<string>('{}');
    const activated = React.useRef(false);

    const serv = new ActivityExecutionService();

    const getFormValues = async (fValues?: IFormValues) => {
        var vals = await getABookFormValues(props.docId, fValues);
        if (activated.current && vals) {
            setFormValues(vals);
        }
    };

    const onSubmit = (form: IChangePerformerFormData) => {
        if (!form?.executor) {
            setErrorText('Не указан исполнитель');
            return;
        }

        setLoading(true);
        return serv
            .saveChangePerformer(props.actionKey, props.activityId, form?.executor)
            .then((res) => {
                setLoading(false);

                props.completeHandler && props.completeHandler(true);
            })
            .catch((err) => {
                setErrorText(err);
            })
            .finally(() => setLoading(false));
    };

    const formState = useForm<IChangePerformerFormData>({
        mode: 'onBlur', // "onChange"
    });

    useEffect(() => {
        setLoading(true);
        activated.current = true;

        serv.getChangePerformerModel(props.actionKey, props.activityId)
            .then((res) => {
                setAbookOptions(res.data);
                getFormValues(res.data?.formValues);
                setLoading(false);
            })
            .catch((err) => {
                setErrorText('Ошибка получения данных от сервера.');
            })
            .finally(() => setLoading(false));
        return () => {
            activated.current = false;
        };
    }, []);

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            <AbookpickerControl
                label="Исполнитель"
                placeholder="Исполнитель"
                isMultiple={false}
                title="Исполнитель"
                diplayValue={executor?.fullName ?? ''}
                formValues={formValues}
                name="executor"
                tabsSettings={abookOptions?.tabs}
                externalSearch={abookOptions?.externalSearch}
                required
                formState={formState.formState}
                showChips={false}
                control={formState.control}
                onSetValues={(value: IAddressBookData[]) => {
                    setExecutor(value[0]);
                }}
            />
        </ModalContainer>
    );
};

interface IChangePerformerFormData {
    executor: string;
}
