import {
    IDocumentTable,
    ITableColumn,
    ITableColumnAbook,
    ITableColumnAutoComplete,
    ITableColumnCalc,
    ITableColumnDict,
} from '@models/Forms/IForms';
import { FormulaManager } from '@utils/FormulaManager';
import { IFieldElem } from '@models/IFormData';

export const isTableColumn = (anyObject: any): anyObject is ITableColumn => {
    return (
        (anyObject as ITableColumn) !== null &&
        (anyObject as ITableColumnDict).dictName === undefined &&
        (anyObject as ITableColumnCalc).formula === undefined &&
        (anyObject as ITableColumnAutoComplete).dataSource == null &&
        (anyObject as ITableColumnAbook).tabs === undefined
    );
};
export const isTableColumnDict = (anyObject: any): anyObject is ITableColumnDict => {
    return (anyObject as ITableColumnDict).dictName !== undefined;
};
export const isTableColumnCalc = (anyObject: any): anyObject is ITableColumnCalc => {
    return (anyObject as ITableColumnCalc).formula !== undefined;
};
export const isTableColumnAutoComplete = (anyObject: any): anyObject is ITableColumnAutoComplete => {
    return (anyObject as ITableColumnAutoComplete).dataSource != null;
};
export const isTableColumnAbook = (anyObject: any): anyObject is ITableColumnAbook => {
    return (anyObject as ITableColumnAbook).tabs !== undefined;
};

export const hasChildWithClass = (baseElement: Element, className: string) => {
    let element: Element = baseElement;
    if (element.classList && element.classList.contains(className)) {
        return true;
    }

    for (let child of element.children) {
        if (hasChildWithClass(child, className)) {
            return true;
        }
    }

    return false;
};

export const isVisibleRowDeleteButton = async (table: IDocumentTable, rowData: any) => {
    if (table.singleRowDeleteButtonRules) {
        let formulaMgr = new FormulaManager(table.singleRowDeleteButtonRules);
        let formulaData: Record<string, IFieldElem> = {} as Record<string, IFieldElem>;
        for (let key in rowData) {
            formulaData[key] = {
                index: 0,
                name: '',
                value: rowData[key],
            };
        }
        formulaMgr.Init(formulaData);
        const result = await formulaMgr.EvalFormulaValues(true, false);
        return result as boolean;
    } else {
        return true;
    }
};
