import './../actions.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IBaseAction } from '@models/actions/IBaseAction';
import { ActivityBaseActionExecutor } from '@utils/actions/ActivityBaseActionExecutor';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { Column, DataGrid, Paging, Scrolling, Selection } from 'devextreme-react/data-grid';
import {
    IResolveExceptionActivityModel,
    IResolveExceptionModel,
} from '@models/document/activityExecutors/IResolveExceptionModel';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@atoms/Modal/Modal';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';

export class ResolveExceptionExecutor extends ActivityBaseActionExecutor {
    private _modalProps?: IResolveExceptionProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            activityId: getActivityIdFromCompositeId(objId),
            actionKey: action.key,
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <DelegateModal {...this._modalProps} /> : <></>;
    };
}

interface IResolveExceptionProps {
    activityId: string;
    actionKey: string;
    title: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const DelegateModal: React.FC<IResolveExceptionProps> = (props: IResolveExceptionProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [actionModel, setActionModel] = useState<IResolveExceptionModel>();
    const [templateId, setTemplateId] = useState<string>('');

    const serv = new ActivityExecutionService();
    const gridRef = useRef<DataGrid>(null);

    useEffect(() => {
        setLoading(true);

        serv.getResolveExceptionModel(props.actionKey, props.activityId)
            .then((res) => {
                setActionModel(res.data);
                setLoading(false);
            })
            .finally(() => setLoading(false));
    }, []);

    const onSubmit = (form: any) => {
        if (templateId == '') {
            setErrorText('Не указан шаг маршрута');
            return;
        }

        setLoading(true);

        return serv
            .saveResolveException(props.actionKey, props.activityId, templateId)
            .then((res) => {
                setLoading(false);

                props.completeHandler && props.completeHandler(true);
            })
            .catch((err) => {
                setErrorText(err);
            })
            .finally(() => setLoading(false));
    };

    const formState = useForm<any>({
        mode: 'onBlur', // "onChange"
    });

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formState,
        onSubmit: onSubmit,
    };

    const onSelectionChanged = useCallback((e: any) => {
        if (e.currentSelectedRowKeys.length > 0) {
            let currentKeys = e.currentSelectedRowKeys.map((data: IResolveExceptionActivityModel) => data.templateId);
            let deselectedRows = e.selectedRowsData
                .filter((data: IResolveExceptionActivityModel) => {
                    return (
                        currentKeys.length > 0 &&
                        !currentKeys.some((currentKey: string) => currentKey == data.templateId)
                    );
                })
                .map((data: IResolveExceptionActivityModel) => data);

            e.component.deselectRows(deselectedRows);

            // Один выбранный элемент
            setTemplateId(currentKeys.length > 0 ? currentKeys[0] : '');
        }
    }, []);

    return (
        <ModalContainer {...modalContainerProps}>
            <DevExpressDataGrid
                dataSource={actionModel?.activities}
                remoteOperations={true}
                hoverStateEnabled={true}
                columnHidingEnabled={false}
                showColumnHeaders={true}
                columnAutoWidth={false}
                allowColumnReordering={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
                noDataText={'Нет доступных этапов маршрута'}
                rowAlternationEnabled={true}
                ref={gridRef}
                onSelectionChanged={onSelectionChanged}
                onCellHoverChanged={onCellHoverChanged}
            >
                <Selection mode="multiple" allowSelectAll={false} showCheckBoxesMode={'always'} />

                <Scrolling mode="virtual" />
                <Paging enabled={true} defaultPageSize={10} />

                <Column caption="Название" dataField="activityName" width={'60%'} />
                <Column caption="Исполнитель" dataField="performerName" />
            </DevExpressDataGrid>
        </ModalContainer>
    );
};
