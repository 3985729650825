import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import { DocActionsExecFactory } from '@utils/actions/DocActionsExecFactory';
import { LocationTypes } from '@models/actions/LocationTypes';
import { DocumentActionsService } from '@services/actions/DocumentActionsService';
import { getDocIdFromLinkedDocId, getParentDocIdFromLinkedDocId } from '@utils/helpers';
import { IActionService } from '@services/actions/IActionService';
import { LinkedDocActionsExecFactory } from '@utils/actions/LinkedDocActionsExecFactory';

export class LinkedDocsActionsService implements IActionService<IBaseAction> {
    public getActionExecutor(act: IBaseAction): IActionExecutor {
        let result = LinkedDocActionsExecFactory.GetActionExecutor(act, false);
        if (result !== undefined) return result;
        return DocActionsExecFactory.GetActionExecutor(act)!;
    }

    //Получает действия с поисковой папки
    async fetchActions(linkedDocId: string): Promise<AxiosResponse<IBaseAction[]>> {
        let parentId = getParentDocIdFromLinkedDocId(linkedDocId);
        let docId = getDocIdFromLinkedDocId(linkedDocId);
        return $api.get<IBaseAction[]>(`document/linkedDocActions/${docId}/${parentId}`);
    }

    //Получает групповые действия с поисковой папки
    async fetchGroupActions(linkedDocIds: string): Promise<AxiosResponse<IBaseAction[]>> {
        let ids = linkedDocIds.split(',');
        let docIds = ids.map((id) => getDocIdFromLinkedDocId(id)).join(',');
        return DocumentActionsService.fetchGroupActionsByLocation(docIds, LocationTypes.DocumentLinks);
    }
}
