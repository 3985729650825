export enum CustomComponentTypes {
    // Неизвестный тип
    Unknown,
    //Кастомное представление выбора победителя. Представление по закупке
    WinnerSelectionTableDemand,
    //Кастомное представление выбора победителя. Полотовое представление
    WinnerSelectionTableLot,
    //Кастомное представление выбора победителя. Попозиционное представление
    WinnerSelectionTablePositions,
    //Кастомное представление выбора победителя. Смешанное представление
    WinnerSelectionTableMixed,
    //Кастомное представление выбора победителя. Квалификационный отбор
    WinnerSelectionTableKO,
    //График поставок
    SupplyScheduleTable,
}
