export interface IDataGridSettings {
    wordWrapMode: DataGridWordWrapMode;
}

export enum DataGridWordWrapMode {
    // Не переносить (в одну строку)
    NoWrap,
    // Ограничение количества строк
    Trim,
    // Переносить
    Wrap,
}
