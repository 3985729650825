import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IDocumentRouteMapAction } from '@models/document/IDocumentRouteMap';
import { IActionService } from '@services/actions/IActionService';
import { RouteMapActionsExecFactory } from '@utils/actions/RouteMapActionsExecFactory';

export class RouteMapActionsService implements IActionService<IBaseAction> {
    public getActionExecutor(act: IBaseAction): IActionExecutor {
        return RouteMapActionsExecFactory.GetActionExecutor(act)!;
    }

    //Получает перечень доступных действий
    async fetchActions(docId: string): Promise<AxiosResponse<IBaseAction[]>> {
        return new Promise<AxiosResponse<IBaseAction[]>>(() => {});
    }

    //Получает перечень доступных групповых действий
    async fetchGroupActions(docIds: string): Promise<AxiosResponse<IBaseAction[]>> {
        // групповые действия не поддерживаются
        return new Promise<AxiosResponse<IBaseAction[]>>(() => {});
    }

    static async fetchActionConfig(
        documentKey: string,
        actionKey: string,
    ): Promise<AxiosResponse<IDocumentRouteMapAction>> {
        return $api.get<IDocumentRouteMapAction>(`routeMapExecution/config/${actionKey}/${documentKey}`);
    }
}
