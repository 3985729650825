import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IChangeControllerModel } from '@models/document/actionsExecution/IChangeControllerModel';

export class ProcessExecutionService {
    removeFromControl(processId: string, comment?: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('comment', comment as string);
        return $api.post(`processActions/removeFromControl/${processId}`, formData);
    }

    async getChangeControllerModel(processId: string): Promise<AxiosResponse<IChangeControllerModel>> {
        return $api.get<IChangeControllerModel>(`processActions/changeController/${processId}`);
    }

    async saveChangeController(processId: string, controllerKey: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('controllerKey', controllerKey);
        return $api.post(`processActions/changeController/${processId}`, formData);
    }
}
