import { IBaseAction } from '@models/actions/IBaseAction';
import { ModalSize } from '@atoms/Modal/Modal';
import { getDocIdFromLinkedDocId } from '@utils/helpers';
import { DocumentActionsService } from '@services/actions/DocumentActionsService';
import { sendErrorMsg } from '@molecules/Errors';
import { IErrorMessage } from '@molecules/Errors/Errors';
import React from 'react';
import { BaseActionExecutor } from '@utils/actions/IActionExecutor';

export abstract class DocBaseActionExecutor extends BaseActionExecutor {
    protected _errors?: string[];
    protected _objId?: string;
    protected _parentId?: string;
    protected _action?: IBaseAction;
    protected _rowData?: any;
    protected _completeHandler?: (isSucceed: boolean) => void;

    run = async (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        this._objId = objId;
        this._parentId = parentId;
        this._action = action;
        this._rowData = rowData;
        this._completeHandler = completeHandler;

        // Если прилетает ИД линкованного документа вида [ИД родителя]/[ИД линкованного документа]
        let objIds = objId.split(',');
        objId = objIds.map((id) => (id.split('/').length === 2 ? getDocIdFromLinkedDocId(id) : id)).join(',');

        let isMultipleObjects = objId.split(',').filter((item) => item).length > 1;
        let serv = new DocumentActionsService();

        const runAction = async () => {
            if (isMultipleObjects && action.hasGroupAccessibility) {
                // проверка accessibility для группы документов
                await serv
                    .checkActionGroupAccessibility(objId, action.key)
                    .then((res) => {
                        if (res.data.isAccessibly) {
                            //успешная проверка групповой Accessibility
                            if (this.runInternal)
                                this.runInternal(objId, parentId, action, rowData, completeHandler, modalSize);
                            if (raiseVisualElement) raiseVisualElement();
                        } else {
                            this._errors = res.data.errors;
                            if (raiseVisualElement) raiseVisualElement();
                        }
                    })
                    .catch(() => {
                        if (completeHandler) completeHandler(false);
                    });
            } else {
                if (this.runInternal) this.runInternal(objId, parentId, action, rowData, completeHandler, modalSize);
                if (raiseVisualElement) raiseVisualElement();
            }
        };

        if (action.hasAccessibility) {
            await serv
                .checkActionAccessibility(objId, action.key)
                .then(async (res) => {
                    if (res.data.isAccessibly) {
                        //успешная проверка Accessibility
                        await runAction();
                    } else {
                        this._errors = res.data.errors;
                        if (raiseVisualElement) raiseVisualElement();
                    }
                })
                .catch(() => {
                    if (completeHandler) completeHandler(false);
                });
        } else {
            await runAction();
        }
    };

    abstract runInternal: (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => void;

    visualElement = () => {
        if ((this._errors?.length ?? 0) > 0) {
            sendErrorMsg({
                message: this._errors,
                closeBtnClick: () => {
                    if (this._completeHandler) this._completeHandler(false);
                },
            } as IErrorMessage);
            return <></>;
        } else {
            return this.visualElementInner ? this.visualElementInner() : <></>;
        }
    };

    visualElementInner?: () => React.ReactElement;
}
