import { ActFinishType } from '@services/actions/ActivityExecutionService';
import { BaseWithSignExecutor } from './BaseWithSignExecutor';

export class RejectWithSignExecutor extends BaseWithSignExecutor {
    constructor() {
        super();

        this.approveModel = {
            commentIsRequired: true,
            actType: ActFinishType.Refuse,
        };
    }
}
