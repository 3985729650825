import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';

import './TableData.scss';
import { IDictFilter, IDocumentTable } from '@models/Forms/IForms';
import { v4 as uuidv4 } from 'uuid';
import DataGrid, {
    Column,
    ColumnChooser,
    Editing,
    FilterRow,
    KeyboardNavigation,
    MasterDetail,
    Paging,
    RowDragging,
    Selection,
    Sorting,
} from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DevExpressDataGrid from '../DevExpress/DataGrid/DevExpressDataGrid';
import { Toolbar } from 'devextreme-react/toolbar';
import EditCellRenderSwitcher from './EditCellRenderSwitcher';
import { MdAdd, MdHelpOutline } from 'react-icons/md';
import { useWatch } from 'react-hook-form';
import Button from '../Button';
import RowEditButton, { IRowEditButtonHandle } from './RowEditButton';
import { IField, IFieldElem } from '@models/IFormData';
import { IValidHandle } from '@models/IValidHandle';
import RowCopyButton from './RowCopyButton';
import ViewCellRenderSwitcher from './ViewCellRenderSwitcher';
import Tooltip from '../Tooltip';
import { classnames } from '@utils/classnames';
import { getColumnDataTypeByFieldDataType, onCellHoverChanged } from '@utils/dataGridUtils';
import { deepEqual, getDiffObj, hashCode } from '@utils/helpers';
import { sendNotification } from '@molecules/Notifications';
import EditRowMultiButton from './EditRowMultiButton';
import Menu from '../Menu';
import { IListElement } from '@/types';
import DictpickerModal from '../Dictpicker/DictpickerModal/DictpickerModal';
import { IDictionaryData } from '@/models/dictionary/IDictionaryData';
import { ValueType } from '@/types/ValueType';
import RowDeleteButton from './RowDeleteButton';
import OpenDocButton from './OpenDocButton';
import { hasChildWithClass, isVisibleRowDeleteButton } from '@utils/tableHelper';
import { TabsContext } from '../Tabs/Tabs';
import { RowPreparedEvent } from 'devextreme/ui/data_grid';
import DeleteRowMultiButton, { IDeleteRowMultiButtonRefActions } from '@atoms/TableData/DeleteRowMultiButton';
import { confirm } from 'devextreme/ui/dialog';
import { ConfigService } from '@/configuration/services/configService';

export interface ITableDataProps {
    value?: any[];
    table: IDocumentTable;
    allowUpdating: boolean;
    showScrollbar?: boolean;
    name: string;
    docId?: string;
    fields: Record<string, IFieldElem>;
    calculateRow: (row: any, column: any, table: IDocumentTable) => Promise<any>;
    onChangeCellValue: (
        row: any,
        oldRow: any,
        column: any,
        table: IDocumentTable,
        withOutUpdate: boolean,
    ) => Promise<void>;
    onTableRowDeleted: (value: any, row: any, table: IDocumentTable) => Promise<void>;
    onTableRowCopied: (value: any, row: any, table: IDocumentTable) => Promise<void>;
    onFormRowEdited: (value: any, row: any, table: IDocumentTable) => Promise<void>;
    cellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    editCellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    evalTableFormulaValue: (condition: string, rowData?: any, rowParent?: any) => Promise<boolean>;
    onInitNewRow?: (row: any, table: IDocumentTable) => Promise<void>;
    onInitCopyRow?: (row: any, table: IDocumentTable) => Promise<void>;
    onChanged?: (e: any[], valid: boolean) => Promise<void>;
    getColumnWatches: (table?: IDocumentTable, rowParent?: any) => string[];
    getWatchesByFormula: (formulas?: string[], rowParent?: any) => string[];
    getParentFields: () => IField[];
    setParentField: (field: IField) => void;
    getFormValuesAsync?: () => Promise<string>;
    getFiltersAsync?: () => Promise<IDictFilter>;
    onSetFormDataNewRow?: (item: any, table: IDocumentTable, data: IDictionaryData) => Promise<void>;
    onValidateExternalRowsData?: (data: IDictionaryData[], table: IDocumentTable) => Promise<boolean>;
}

const TableData = forwardRef<IValidHandle, ITableDataProps>(
    (
        {
            value,
            table,
            name,
            allowUpdating,
            showScrollbar = true,
            fields,
            calculateRow,
            onChangeCellValue,
            onValidateExternalRowsData,
            onTableRowDeleted,
            onTableRowCopied,
            onFormRowEdited,
            cellRenderSwitcher,
            editCellRenderSwitcher,
            evalTableFormulaValue,
            onInitNewRow,
            onInitCopyRow,
            onChanged,
            getColumnWatches,
            getWatchesByFormula,
            getParentFields,
            setParentField,
            docId,
            getFormValuesAsync,
            getFiltersAsync,
            onSetFormDataNewRow,
        }: ITableDataProps,
        ref,
    ) => {
        const config = ConfigService.get();
        const scrollByContent = config.application._experimental_scrollByContentInTables ?? false;

        const changesStack = React.useRef<any[]>([]);
        const hashColumns = React.useRef<string>();

        // Задача EUPDEV-8738
        // Неправильно отображалась высота заголовков таблиц, при переходе на вкладку
        // Решение: принудительно обновлять размеры таблиц при смене вкладок
        const tabsContext = useContext(TabsContext);
        useEffect(() => {
            gridRef.current?.instance.updateDimensions();
        }, [tabsContext.activeTab]);

        useImperativeHandle(ref, () => ({
            valid() {
                let result = isValid.current;
                console.log('TableData: ' + table.name + ' : ' + result);
                for (let tableName in detailsTablesRef.current) {
                    const table = detailsTablesRef.current[tableName];
                    result = result && table.valid();
                }
                return result;
            },
            getData() {
                let store = dataSource.store() as any;
                let items = store._array;
                return items;
            },
            setData(data: any[]) {
                if (changesStack.current.indexOf(data) === -1) {
                    changesStack.current.push(data);
                }
                if (changesStack.current.length == 1) {
                    updateStore(data);
                }
            },
        }));
        const detailsTables: { [id: string]: IDocumentTable } = {};
        const activated = React.useRef(false);
        const gridRef = useRef<DataGrid>(null);

        const editInFormRef = React.useRef<IRowEditButtonHandle>(null);
        const showEditFormModal = React.useRef<boolean>(false);
        const deleteRowMultiRef = React.useRef<IDeleteRowMultiButtonRefActions>(null);

        const renderMasterDetail = (tables: IDocumentTable[]) => {
            tables.forEach((table) => {
                detailsTables[table.key] = table;
            });
            return <MasterDetail key={table.key} enabled={true} render={renderDetailTable} />;
        };

        const [columns, setColumns] = useState<JSX.Element[]>();
        const valuesSubTables: { [id: string]: any } = {};
        const [requiredColumnKeys, setRequiredColumnKeys] = useState<{ [id: string]: string }>({});
        const [minValidColumnKeys, setMinValidColumnKeys] = useState<{ [id: string]: number }>({});
        const [maxValidColumnKeys, setMaxValidColumnKeys] = useState<{ [id: string]: number }>({});
        const [patternColumnKeys, setPatternColumnKeys] = useState<{ [id: string]: string }>({});
        const [canAddRows, setCanAddRows] = useState<boolean>(true);
        const [canAddRowsFormData, setCanAddRowsFormData] = useState<boolean>(true);
        const [showAddExternalRows, setShowAddExternalRows] = useState<boolean>(false);
        const [canRemoveRows, setCanRemoveRows] = useState<boolean>(true);
        const [hasEditRowMulti, setHasEditRowMulti] = useState<boolean>(false);
        const isValid = React.useRef(true);

        const detailsTablesRef = React.useRef<Record<string, IValidHandle>>({});
        const dictDisplayWatchRef = React.useRef<Record<string, string>>({});

        const getDisplayWatch = (displayFormat: string) => {
            const regex = /{(?<watch>\|.*?)\}/gm;
            let result: string[] = [];
            let m;
            while ((m = regex.exec(displayFormat)) !== null) {
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                m.forEach((match, groupIndex) => {
                    result.push(match);
                });
            }
            return result;
        };

        const InitColumns = async () => {
            let result: any[] = [];
            let reqKeys: { [id: string]: string } = {};
            let minKeys: { [id: string]: number } = {};
            let maxKeys: { [id: string]: number } = {};
            let patternKeys: { [id: string]: string } = {};
            let hash = '';
            for (let index = 0; index < table.tableColumn.length; index++) {
                const column = table.tableColumn[index];
                let vis = column.hidden || (await checkVisRules(column));

                if (vis) {
                    if (column.required !== undefined && column.required !== null && column.required !== 'false') {
                        reqKeys[column.key] = column.required;
                    }
                    if (column.min !== undefined && column.min !== null && column.min != '') {
                        minKeys[column.key] = +column.min;
                    }
                    if (column.max !== undefined && column.max !== null && column.max != '') {
                        maxKeys[column.key] = +column.max;
                    }
                    if (column.inputRegExp !== undefined && column.inputRegExp !== null && column.inputRegExp !== '') {
                        patternKeys[column.key] = column.inputRegExp;
                    }
                    let editRowMulti = await checkEditRowMulti(column);
                    if (editRowMulti && !hasEditRowMulti) {
                        setHasEditRowMulti(true);
                    }

                    let coll = await renderColumnGrid(
                        column,
                        `table_grid_${table.key}`,
                        editRowMulti,
                        column.valueType,
                    );
                    hash = hash + coll.component.key;
                    result.push(coll);
                }
            }
            for (let index = 0; index < table.tableColumnDict.length; index++) {
                const column = table.tableColumnDict[index];
                let vis = await checkVisRules(column);
                if (vis) {
                    let editRowMulti = await checkEditRowMulti(column);
                    if (editRowMulti && !hasEditRowMulti) {
                        setHasEditRowMulti(true);
                    }
                    if (column.required !== undefined && column.required !== null && column.required !== 'false') {
                        reqKeys[column.key] = column.required;
                    }
                    let watchKeys = getDisplayWatch(column.displayFormat);
                    watchKeys.forEach((watchKey) => {
                        dictDisplayWatchRef.current[watchKey] = column.key;
                    });

                    let coll = await renderColumnGrid(
                        column,
                        `table_dictgrid_${table.key}`,
                        editRowMulti,
                        ValueType.Text,
                    );
                    hash = hash + coll.component.key;
                    result.push(coll);
                }
            }
            for (let index = 0; index < table.tableColumnAbook.length; index++) {
                const column = table.tableColumnAbook[index];
                let vis = await checkVisRules(column);
                if (vis) {
                    let editRowMulti = await checkEditRowMulti(column);
                    if (editRowMulti && !hasEditRowMulti) {
                        setHasEditRowMulti(true);
                    }
                    if (column.required !== undefined && column.required !== null && column.required !== 'false') {
                        reqKeys[column.key] = column.required;
                    }

                    let coll = await renderColumnGrid(
                        column,
                        `table_abookgrid_${table.key}`,
                        editRowMulti,
                        ValueType.Text,
                    );
                    hash = hash + coll.component.key;
                    result.push(coll);
                }
            }
            for (let index = 0; index < table.tableColumnCalc.length; index++) {
                const column = table.tableColumnCalc[index];
                let vis = await checkVisRules(column);
                if (vis) {
                    let editRowMulti = await checkEditRowMulti(column);
                    if (editRowMulti && !hasEditRowMulti) {
                        setHasEditRowMulti(true);
                    }
                    if (column.required !== undefined && column.required !== null && column.required !== 'false') {
                        reqKeys[column.key] = column.required;
                    }

                    let coll = await renderColumnGrid(
                        column,
                        `table_calcgrid_${table.key}`,
                        editRowMulti,
                        ValueType.Double,
                    );
                    hash = hash + coll.component.key;
                    result.push(coll);
                }
            }
            for (let index = 0; index < table.tableColumnAutoComplete.length; index++) {
                const column = table.tableColumnAutoComplete[index];
                let vis = await checkVisRules(column);
                if (vis) {
                    let editRowMulti = await checkEditRowMulti(column);
                    if (editRowMulti && !hasEditRowMulti) {
                        setHasEditRowMulti(true);
                    }
                    if (column.required !== undefined && column.required !== null && column.required !== 'false') {
                        reqKeys[column.key] = column.required;
                    }

                    let coll = await renderColumnGrid(
                        column,
                        `table_autocompletegrid_${table.key}`,
                        editRowMulti,
                        ValueType.Text,
                    );
                    hash = hash + coll.component.key;
                    result.push(coll);
                }
            }

            if (activated.current) {
                let coll = result
                    .sort((a, b) => (a.orderId < b.orderId ? -1 : 1))
                    .map((x) => {
                        return x.component;
                    });

                if (hashColumns.current !== hash) {
                    setColumns(coll);
                    hashColumns.current = hash;
                }

                setRequiredColumnKeys(reqKeys);
                setMinValidColumnKeys(minKeys);
                setMaxValidColumnKeys(maxKeys);
                setPatternColumnKeys(patternKeys);
            }
            return null;
        };

        const InitCanAddRows = async () => {
            if (table?.addRowButtonRules && evalTableFormulaValue) {
                let res = true;
                if (table?.addRowButtonRules) res = await evalTableFormulaValue(table?.addRowButtonRules);

                setCanAddRows(res);
            }
        };
        const InitCanAddRowsFormData = async () => {
            if (table?.addFormDataRows?.addRowButtonRules && evalTableFormulaValue) {
                let res = true;

                if (table?.addFormDataRows?.addRowButtonRules)
                    res = await evalTableFormulaValue(table?.addFormDataRows.addRowButtonRules);

                setCanAddRowsFormData(res);
            }
        };

        const InitCanRemoveRows = async () => {
            if (table?.removeRowButtonRules && evalTableFormulaValue) {
                let res = await evalTableFormulaValue(table?.removeRowButtonRules);
                setCanRemoveRows(res);
            }
        };
        const watchesRemoveRows = useWatch({
            name: getWatchesByFormula([table?.removeRowButtonRules]),
        });
        useEffect(() => {
            InitCanRemoveRows();
        }, [watchesRemoveRows]);

        const watches = useWatch({
            name: getColumnWatches(table),
        });

        const watchesAddRows = useWatch({
            name: getWatchesByFormula([table?.addRowButtonRules]),
        });
        const watchesAddRowsFormData = useWatch({
            name: getWatchesByFormula([table?.addFormDataRows?.addRowButtonRules]),
        });

        useEffect(() => {
            InitCanAddRows();
        }, [watchesAddRows]);

        useEffect(() => {
            InitCanAddRows();
            activated.current = true;
            InitColumns();
            return () => {
                activated.current = false;
            };
        }, []);

        useEffect(() => {
            InitCanAddRowsFormData();
        }, [watchesAddRowsFormData]);

        useEffect(() => {
            InitCanAddRowsFormData();
            activated.current = true;
            InitColumns();
            return () => {
                activated.current = false;
            };
        }, []);
        useEffect(() => {
            if (watches) {
                if (!watches.every((element) => element === undefined)) {
                    InitColumns();
                }
            }
        }, [watches]);

        useEffect(() => {
            if (value) {
                calcDefValue(value);
                checkAndSetIsValid(value);
            }
        }, [value, name]);

        useEffect(() => {
            if (dataSource) {
                let store = dataSource.store() as any;
                let items = store._array;
                let arr: any[] = [];
                items.forEach((el: any) => {
                    arr.push(el);
                });
                if (arr.length > 0) {
                    checkAndSetIsValid(arr);
                }
            }
        }, [requiredColumnKeys, minValidColumnKeys, maxValidColumnKeys]);

        const renderDetailTable = (param: any) => {
            let keys = Object.keys(detailsTables);

            return keys.map((key, i) => {
                let subTable = detailsTables[key];
                let store = dataSource.store();
                let keyStore = store.key() as string;
                let items = [...(store as any)._array];
                let data = items.find((x) => x[keyStore] == param.key);
                let val = data[key] ? data[key] : [];
                let saved = valuesSubTables[name + subTable.key + param.key];
                if (saved === undefined || !deepEqual(val, saved)) {
                    valuesSubTables[name + subTable.key + param.key] = val;
                } else {
                    val = saved;
                }
                let uniq = keys.length > 1 ? new Date().getTime() : '';
                console.log(name + subTable.key + param.key);

                return (
                    <TableData
                        key={name + subTable.key + param.key}
                        name={name + subTable.key + param.key + uniq}
                        ref={(element) => (detailsTablesRef.current[name + subTable.key + param.key] = element!)}
                        docId={docId}
                        table={subTable}
                        value={val}
                        fields={fields}
                        getParentFields={getParentFields}
                        setParentField={setParentField}
                        onTableRowDeleted={onTableRowDeleted}
                        onTableRowCopied={onTableRowCopied}
                        onFormRowEdited={onFormRowEdited}
                        allowUpdating={allowUpdating}
                        evalTableFormulaValue={async (rules: string, rowData?: any, rowParent?: any) => {
                            return await evalTableFormulaValue(rules, rowData, param.data);
                        }}
                        calculateRow={calculateRow}
                        onInitNewRow={onInitNewRow}
                        onInitCopyRow={onInitCopyRow}
                        showScrollbar={false}
                        onChangeCellValue={onChangeCellValue}
                        cellRenderSwitcher={async (p: any, column: any, rowParent?: any) => {
                            return await cellRenderSwitcher(p, column, param.data);
                        }}
                        editCellRenderSwitcher={async (p: any, column: any, rowParent?: any) => {
                            return await editCellRenderSwitcher(p, column, param.data);
                        }}
                        getColumnWatches={(table?: IDocumentTable, rowParent?: any) => {
                            return getColumnWatches(table, param.data);
                        }}
                        getWatchesByFormula={(formulas?: string[], rowParent?: any) => {
                            return getWatchesByFormula(formulas, param.data);
                        }}
                        onChanged={async (e, valid) => {
                            let store = dataSource.store() as any;
                            let objCopy = { ...param.data };

                            objCopy[key] = e;
                            objCopy = await calculateRow(objCopy, undefined, table);

                            valuesSubTables[name + subTable.key + param.key] = e;
                            ////  store.push([{ type: 'update', data: objCopy, key: param.key }]);
                            store.update(param.key, objCopy).then(async () => {
                                await onSaved(valid);
                            });
                            // onSaved(valid);
                        }}
                    />
                );
            });
        };
        const dataSource = useMemo(() => {
            return new DataSource({
                pushAggregationTimeout: 100,
                store: new ArrayStore({
                    key: '|NUM',
                }),
                onChanged: (e: any) => {
                    // invokeRepaintRows(e);
                    invokeNextChanges();
                },
            });
        }, [name]);

        const invokeRepaintRows = (e: any) => {
            if (e && e.length > 0) {
                let needItemsRefresh: number[] = [];
                e.forEach((changeItem: any) => {
                    let keyColl = Object.keys(changeItem);

                    keyColl.forEach((propKey) => {
                        if (dictDisplayWatchRef.current[propKey]) {
                            let needItemRefresh = gridRef.current?.instance.getRowIndexByKey(changeItem['|NUM']);
                            if (needItemRefresh != undefined) {
                                needItemsRefresh.push(needItemRefresh);
                            }
                        }
                    });
                });

                gridRef.current?.instance.repaintRows(needItemsRefresh);
            }
        };

        const invokeNextChanges = () => {
            changesStack.current.shift();
            if (changesStack.current.length > 0) {
                updateStore(changesStack.current[0]);
            }
        };

        const updateStore = (data: any[]) => {
            let store = dataSource.store();
            let key = store.key() as string;
            let items = [...(store as any)._array];
            // let changes: Array<{
            //     type: 'insert' | 'update' | 'remove';
            //     data?: any;
            //     key?: any;
            //     index?: number;
            // }> = [];
            let promiseColl: Promise<any>[] = [];

            data.forEach((element: any) => {
                let keyVal = element[key];

                store.byKey(keyVal).then(
                    (e: any) => {
                        let diffObj = getDiffObj(e, element);
                        let keyColl = Object.keys(diffObj);

                        keyColl.forEach((propKey) => {
                            let subtable = detailsTablesRef.current[name + propKey + keyVal];
                            let obj = (diffObj as any)[propKey];
                            if (subtable && obj) {
                                subtable.setData(obj);
                            }
                        });

                        if (keyColl.length > 0) {
                            promiseColl.push(store.update(keyVal, diffObj));
                        }
                    },
                    () => {
                        promiseColl.push(store.insert(element));
                    },
                );
            });
            items.forEach((item: any) => {
                let index = data.findIndex((d) => d[key] === item[key]);
                if (index === -1) {
                    promiseColl.push(store.remove(item[key]));
                }
            });
            if (promiseColl.length > 0) {
                Promise.all(promiseColl).then((values) => {
                    invokeRepaintRows(values);
                    dataSource.reload().then(() => {
                        invokeNextChanges();
                    });
                });
            } else {
                invokeNextChanges();
            }
        };

        const renderEditInFormActColumn = (table: IDocumentTable) => {
            return (
                <Column
                    key={`editInForm`}
                    width="36px"
                    //fixed={true}
                    visibleIndex={0}
                    encodeHtml={true}
                    allowResizing={false}
                    allowHiding={false}
                    allowReordering={false}
                    alignment="center"
                    cssClass="dx-command-edit dx-command-edit-with-icons dx-cell-focus-disabled"
                    cellRender={(p) => {
                        let store = dataSource.store() as any;
                        let items = store._array;
                        return (
                            <RowEditButton
                                ref={editInFormRef}
                                getParentFields={getParentFields}
                                table={table}
                                docId={docId}
                                rowData={p.data}
                                displayFormula={table.editInFormFormula}
                                onSubmit={(data, rowIndex) => {
                                    let rowData = items[rowIndex];
                                    onSubmit(data, rowData);
                                }}
                                rowArray={items}
                                onMounted={() => {
                                    if (showEditFormModal.current) {
                                        editInFormRef.current?.showModal();
                                        showEditFormModal.current = false;
                                    }
                                }}
                            />
                        );
                    }}
                />
            );
        };

        const renderDeleteRowColumn = (table: IDocumentTable) => {
            return (
                <Column
                    key={`deleteRow`}
                    width="36px"
                    // fixed={true}
                    visibleIndex={0}
                    encodeHtml={true}
                    allowResizing={false}
                    allowHiding={false}
                    allowReordering={false}
                    alignment="center"
                    cssClass="dx-command-edit dx-command-edit-with-icons dx-cell-focus-disabled"
                    headerCellRender={(p) => {
                        return <DeleteRowMultiButton ref={deleteRowMultiRef} onClick={onDeleteRowMulti} />;
                    }}
                    cellRender={(p) => {
                        return (
                            <RowDeleteButton
                                table={table}
                                docId={docId}
                                rowData={p.data}
                                onDelete={(data) => {
                                    onDelete(data);
                                }}
                            />
                        );
                    }}
                />
            );
        };

        const renderCopyRowColumn = (table: IDocumentTable) => {
            return (
                <Column
                    key={`copyRow`}
                    width="36px"
                    // fixed={true}
                    visibleIndex={0}
                    encodeHtml={true}
                    allowResizing={false}
                    allowHiding={false}
                    allowReordering={false}
                    alignment="center"
                    cssClass="dx-command-edit dx-command-edit-with-icons dx-cell-focus-disabled"
                    cellRender={(p) => {
                        return (
                            <RowCopyButton
                                table={table}
                                docId={docId}
                                rowData={p.data}
                                onCopy={(data) => {
                                    onCopy(data);
                                }}
                            />
                        );
                    }}
                />
            );
        };

        const saveEditRowMulti = async (data: IField[], column: any) => {
            let keys = gridRef.current?.instance.getSelectedRowKeys();
            let store = dataSource.store();
            if (keys && keys?.length > 0) {
                let changes: Array<{
                    type: 'insert' | 'update' | 'remove';
                    data?: any;
                    key?: any;
                    index?: number;
                }> = [];

                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];

                    await store.byKey(key).then(
                        async (e: any) => {
                            let origItem = JSON.parse(JSON.stringify(e));
                            let objCopy = { ...e };
                            data.forEach((fields: IField) => {
                                if (fields.name.indexOf('|Document') === -1) {
                                    objCopy[fields.name] = fields.value;
                                }
                            });

                            await onChangeCellValue(objCopy, origItem, column, table, true).then(async () => {
                                await calculateRow(objCopy, column, table).then((data) => {
                                    objCopy = data;
                                });
                            });

                            let diffObj = getDiffObj(origItem, objCopy);
                            if (Object.keys(diffObj).length > 0) {
                                changes.push({ type: 'update', data: diffObj, key: key });
                                await store.update(key, diffObj);
                            }
                        },
                        () => {
                            changes.push({
                                type: 'update',
                                data: {
                                    [data[0].name]: data[0].value,
                                },
                                key: key,
                            });
                        },
                    );
                }

                store.push(changes);
                await onSaved();
            }
        };
        const canEditRowMulti = (column: any) => {
            const grid = gridRef.current?.instance;
            if (!grid) return false;

            // проверка количества выбранных строк
            let keys = grid.getSelectedRowKeys();
            if (!keys || keys?.length <= 0) {
                sendNotification({
                    message: 'Необходимо выбрать минимум одну строку',
                    variant: 'red',
                });

                return false;
            }

            //  проверка на наличие строк только для чтения
            const haveReadonly = keys?.some((key) => {
                const rowIndex = grid.getRowIndexByKey(key);
                const cellElement = grid.getCellElement(rowIndex, column.key);
                if (!cellElement) return false;

                return hasChildWithClass(cellElement, 'readonly-cell');
            });
            if (haveReadonly) {
                sendNotification({
                    message: 'Среди выбранных строк есть строка только для чтения',
                    variant: 'red',
                });
                return false;
            }

            return true;
        };

        const renderColumnGrid = async (column: any, path: string, editRowMulti: boolean, dataType: ValueType) => {
            return {
                orderId: column.order,
                component: (
                    <Column
                        key={`column${path}${column.key}`}
                        dataField={column.key}
                        caption={column.name}
                        visible={!column.hidden}
                        width={column.width}
                        dataType={getColumnDataTypeByFieldDataType(dataType)}
                        minWidth={
                            // Если ширина в vw, то задаем мин ширину 5px
                            // У грида есть баг, он сравнивает width и minWidth только по цифре, без учета единиц
                            column.width ? (column.width.toString().endsWith('vw') ? 5 : undefined) : undefined
                        }
                        sortIndex={column.sortIndex}
                        sortOrder={column.sortOrder}
                        visibleIndex={3}
                        encodeHtml={true}
                        alignment={column.alignment}
                        headerCellRender={(p) => {
                            return (
                                <div className="title-column-box">
                                    <div
                                        className={classnames(
                                            'title-column-caption',
                                            column.headerNoEllipsis && 'title-column-caption-noEllipsis',
                                        )}
                                    >
                                        {p.column.caption}
                                    </div>
                                    {column.title && (
                                        <div className="title-column-title">
                                            <Tooltip openDelay={100} background="black" position="bottom">
                                                <MdHelpOutline size="16" />
                                                {column.title}
                                            </Tooltip>
                                        </div>
                                    )}
                                    {editRowMulti && (
                                        <div className="title-column-title">
                                            <EditRowMultiButton
                                                getParentFields={getParentFields}
                                                column={column}
                                                canEditRowMulti={canEditRowMulti}
                                                onSubmit={async (data: IField[]) => {
                                                    await saveEditRowMulti(data, column);
                                                }}
                                                table={table}
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                        cellRender={(p) => {
                            return (
                                <ViewCellRenderSwitcher
                                    data={p}
                                    column={column}
                                    cellRenderSwitcher={cellRenderSwitcher}
                                />
                            );
                        }}
                        editCellComponent={(e: any) => {
                            return (
                                <EditCellRenderSwitcher
                                    data={e.data}
                                    column={column}
                                    editCellRenderSwitcher={editCellRenderSwitcher}
                                />
                            );
                        }}
                    ></Column>
                ),
            };
        };

        const calcDefValue = async (data: any[]) => {
            let store = dataSource.store() as any;
            let items = store._array;
            let arr: any[] = [];
            items.forEach((el: any) => {
                arr.push(el);
            });
            if (!deepEqual(value, arr)) {
                store.clear();
                if (data) {
                    for (let index = 0; index < data.length; index++) {
                        const obj = data[index];
                        let objCopy = { ...obj };
                        store.insert(objCopy);
                    }
                }
            }
        };

        const checkAndSetIsValid = async (data: any[]) => {
            let keys = Object.keys(requiredColumnKeys);
            if (keys && keys.length > 0) {
                isValid.current = await checkValidDataSource(data);
                console.log('checkAndSetIsValid: ', isValid.current);
            }
            let keysMinValid = Object.keys(minValidColumnKeys);
            if (keysMinValid && keysMinValid.length > 0) {
                isValid.current = isValid.current && checkMinValidDataSource(data);
                console.log('checkMinValidDataSource: ', isValid.current);
            }
            let keysMaxValid = Object.keys(maxValidColumnKeys);
            if (keysMaxValid && keysMaxValid.length > 0) {
                isValid.current = isValid.current && checkMaxValidDataSource(data);
                console.log('checkMaxValidDataSource: ', isValid.current);
            }
            let keysPattern = Object.keys(patternColumnKeys);
            if (keysPattern && keysPattern.length > 0) {
                isValid.current = isValid.current && (await checkPatternDataSource(data));
                console.log('checkAndSetIsValid: ', isValid.current);
            }
        };

        const onSubmit = async (data: IField[], rowData: any) => {
            for (let index = 0; index < data.length; index++) {
                const field = data[index];
                if (field.name.includes('|Document')) {
                    setParentField(field);
                } else {
                    rowData[field.name] = field.value;
                }
            }

            let store = dataSource.store();
            let key = store.key() as string;
            let changes: Array<{
                type: 'insert' | 'update' | 'remove';
                data?: any;
                key?: any;
                index?: number;
            }> = [];

            var keyVal = rowData[key];

            changes.push({ type: 'update', data: rowData, key: keyVal });

            store.push(changes);
            onSaved().then(() => {
                onFormRowEdited(rowData, rowData, table);
            });
        };

        const onCopy = async (rowData: any) => {
            let dataGrid = gridRef.current?.instance;
            await dataGrid?.deselectAll();

            let store = dataSource.store() as ArrayStore;
            let item = { ...rowData };
            item['|NUM'] = uuidv4();

            // Для Таблиц-Линков. Зачищаем ИД документа
            if ('|doc_Id' in item) {
                item['|doc_Id'] = '';
            }

            if (onInitCopyRow) {
                await onInitCopyRow(item, table);
            }
            await store.insert(item);
            await dataGrid?.refresh();
            await onSaved();
            await onTableRowCopied(item, item, table);
        };

        const onDelete = async (rowData: any) => {
            let dataGrid = gridRef.current?.instance;
            if (dataGrid) {
                await dataGrid.deselectAll();
                let store = dataSource.store();
                let key = store.key() as string;
                await store.remove(rowData[key]);
                await dataGrid?.refresh();
                await onSaved();
                await onTableRowDeleted(rowData, rowData, table);
            }
        };

        const onDeleteRowMulti = useCallback(
            async (e: React.MouseEvent) => {
                const grid = gridRef.current?.instance;
                const selectedRows = grid?.getSelectedRowsData();
                if (selectedRows) {
                    let result = confirm(
                        '<p>Вы действительно хотите удалить выбранные строки?</p>' +
                            '<p>Будут удалены только строки доступные для удаления.</p>',
                        'Удаление строк',
                    );
                    result.then(async (dialogResult) => {
                        if (dialogResult) {
                            // Filter rows based on isVisibleRowDeleteButton
                            const allowedRows = [];
                            for (const item of selectedRows) {
                                const isAllowed = await isVisibleRowDeleteButton(table, item);
                                if (isAllowed) {
                                    allowedRows.push(item);
                                }
                            }
                            if (allowedRows.length > 0) {
                                // Only delete rows that passed the isVisibleRowDeleteButton check
                                const promises = allowedRows.map((item) => onDelete(item));
                                await Promise.all(promises);
                            }
                            grid?.deselectAll();
                        }
                    });
                }
            },
            [onDelete],
        );

        const checkVisRules = async (column: any) => {
            return (
                column.visibilityRules == undefined ||
                column.visibilityRules == null ||
                (column.visibilityRules && (await evalTableFormulaValue(column.visibilityRules)))
            );
        };
        const checkEditRowMulti = async (column: any) => {
            return !(
                column.editRowMulti == undefined ||
                column.editRowMulti == null ||
                (column.editRowMulti && !(await evalTableFormulaValue(column.editRowMulti)))
            );
        };

        const checkValidDataSource = async (e: any) => {
            let coll = e as any[];
            let rowsCount = coll.length;

            let keys = Object.keys(requiredColumnKeys);
            for (let indexCol = 0; indexCol < keys.length; indexCol++) {
                for (let index = 0; index < rowsCount; index++) {
                    let key = keys[indexCol];
                    let rule = requiredColumnKeys[key];
                    let row = coll[index];
                    let req = rule === 'true' ? true : await evalTableFormulaValue(rule, row);
                    if (req) {
                        let val = row[key];
                        if (val === undefined || val === null || val === '') {
                            return false;
                        }
                    }
                }
            }
            return true;
        };
        const checkMinValidDataSource = (e: any) => {
            let coll = e as any[];
            let rowsCount = coll.length;

            let keys = Object.keys(minValidColumnKeys);
            for (let indexCol = 0; indexCol < keys.length; indexCol++) {
                for (let index = 0; index < rowsCount; index++) {
                    let key = keys[indexCol];
                    let min = minValidColumnKeys[key];
                    let row = coll[index];

                    let val = row[key];
                    if (val === undefined || val === null || val === '' || val.length < min) {
                        return false;
                    }
                }
            }
            return true;
        };
        const checkMaxValidDataSource = (e: any) => {
            let coll = e as any[];
            let rowsCount = coll.length;

            let keys = Object.keys(maxValidColumnKeys);
            for (let indexCol = 0; indexCol < keys.length; indexCol++) {
                for (let index = 0; index < rowsCount; index++) {
                    let key = keys[indexCol];
                    let max = maxValidColumnKeys[key];
                    let row = coll[index];

                    let val = row[key];
                    if (val.length > max) {
                        return false;
                    }
                }
            }
            return true;
        };
        const checkPatternDataSource = async (e: any) => {
            let coll = e as any[];
            let rowsCount = coll.length;

            let keys = Object.keys(patternColumnKeys);
            for (let indexCol = 0; indexCol < keys.length; indexCol++) {
                for (let index = 0; index < rowsCount; index++) {
                    let key = keys[indexCol];
                    let rule = patternColumnKeys[key];
                    let regexp = rule ? new RegExp(rule) : null;
                    let row = coll[index];
                    let val = row[key];
                    if (regexp && !regexp.test(val)) {
                        return false;
                    }
                }
            }
            return true;
        };

        const _listMenuAddRow: IListElement[] = [
            {
                value: '1',
                label: 'Добавить строку',
                handler: () => addRow(),
            },
            {
                value: '2',
                label: table?.addFormDataRows?.name,
                handler: () => {
                    setShowAddExternalRows(true);
                },
            },
        ];

        const _listMenuAddFormDataRow: IListElement[] = [
            {
                value: '2',
                label: table?.addFormDataRows?.name,
                handler: () => {
                    setShowAddExternalRows(true);
                },
            },
        ];

        const onValidateExternalRows = async (data: IDictionaryData[]) => {
            let result = true;
            if (table?.addFormDataRows?.validators?.validators?.length > 0 && onValidateExternalRowsData) {
                result = await onValidateExternalRowsData(data, table);
            }

            return result;
        };

        const addExternalRows = async (data: IDictionaryData[]) => {
            let dataGrid = gridRef.current?.instance;
            await dataGrid?.deselectAll();

            let store = dataSource.store() as ArrayStore;
            let items = (store as any)._array;
            let codeField = table?.addFormDataRows.setValues.sets.find((x) => x.attr === 'code');

            for (let index = 0; index < data.length; index++) {
                if (
                    items.findIndex(
                        (x: any) => codeField && x[codeField.key].toString() === data[index].code.toString(),
                    ) > -1
                )
                    continue;

                let item: any = {};
                item['|NUM'] = uuidv4();
                const select = data[index];
                onSetFormDataNewRow && (await onSetFormDataNewRow(item, table, select));

                if (onInitNewRow) {
                    await onInitNewRow(item, table);
                }

                await store.insert(item);
            }

            await dataGrid?.refresh();

            await onSaved();
        };

        const getSelectedExternalKeys = () => {
            let store = dataSource.store();
            let items = (store as any)._array;
            let codeField = table?.addFormDataRows.setValues.sets.find((x) => x.attr === 'code');
            let arr: string[] = [];
            if (codeField) {
                for (let index = 0; index < items.length; index++) {
                    let item = items[index];
                    arr.push(item[codeField.key]);
                }
            }

            return arr;
        };

        const addRow = async () => {
            let dataGrid = gridRef.current?.instance;
            await dataGrid?.deselectAll();
            let store = dataSource.store() as ArrayStore;
            let item: any = {};
            item['|NUM'] = uuidv4();
            if (onInitNewRow) {
                await onInitNewRow(item, table);
            }
            await store.insert(item);
            await dataGrid?.refresh();
            if (table.addRowFocusColumn) {
                let index = await store.totalCount({});
                dataGrid?.editCell(index - 1, table.addRowFocusColumn);
            }
            await onSaved();

            // Отобразить форму редактирования при добавлении элемента
            showEditFormModal.current = table.showEditFormOnAddRow;
        };

        const getSelectedRowElements = (keys: any[]) => {
            let arr: Element[] = [],
                idx: number,
                el: Element;
            if (gridRef.current) {
                let dataGrid = gridRef.current.instance;
                keys.forEach((key) => {
                    idx = dataGrid.getRowIndexByKey(key);
                    let row = dataGrid.getRowElement(idx);
                    if (row) {
                        el = row[0];
                    }
                    arr.push(el);
                });
            }
            return arr;
        };

        const removeRows = (items: any, rowsData: any) => {
            rowsData.forEach((row: any) => {
                const index = items.indexOf(row);

                if (index >= 0) {
                    items.splice(index, 1);
                }
            });
        };

        const addRowsToIdx = (items: any, rowsData: any, currIndexRow: any, toIndex: number) => {
            if (toIndex >= 0) {
                toIndex = currIndexRow ? items.indexOf(currIndexRow) + 1 : toIndex;
                items.splice.apply(items, [toIndex, 0].concat(rowsData));
            }
        };

        const onSaved = async (validExternal?: boolean) => {
            let store = dataSource.store() as any;
            let items = store._array;
            let arr: any[] = [];
            items.forEach((el: any) => {
                arr.push(el);
            });

            let valid = await checkValidDataSource(arr);
            let resultValid = validExternal !== undefined ? validExternal && valid : valid;

            isValid.current = resultValid;
            onChanged && (await onChanged(arr, resultValid));
        };

        const keyHash = useMemo(() => {
            return hashCode(table.key);
        }, [table.key]);

        const onChangedRows = async (e: any) => {
            let keysNew = Object.keys(e.newData);
            let visColumns = e.component.getVisibleColumns();
            let col = visColumns.filter((col: any) => {
                return col.dataField === keysNew[0];
            });
            if (keysNew.length > 0 && col.length > 0 && col[0].caption) {
                let caption = col[0].caption;
                let column: any = undefined;
                let cols = table.tableColumn.filter((col) => {
                    return col.key === keysNew[0] && col.name === caption;
                });
                column = cols.length > 0 ? cols[0] : undefined;
                if (column == undefined) {
                    let cols = table.tableColumnAbook.filter((col) => {
                        return col.key === keysNew[0] && col.name === caption;
                    });
                    column = cols.length > 0 ? cols[0] : undefined;
                }
                if (column == undefined) {
                    let cols = table.tableColumnCalc.filter((col) => {
                        return col.key === keysNew[0] && col.name === caption;
                    });
                    column = cols.length > 0 ? cols[0] : undefined;
                }
                if (column == undefined) {
                    let cols = table.tableColumnDict.filter((col) => {
                        return col.key === keysNew[0] && col.name === caption;
                    });
                    column = cols.length > 0 ? cols[0] : undefined;
                }
                if (column == undefined) {
                    let cols = table.tableColumnAutoComplete.filter((col) => {
                        return col.key === keysNew[0] && col.name === caption;
                    });
                    column = cols.length > 0 ? cols[0] : undefined;
                }
                let origItem = JSON.parse(JSON.stringify(e.oldData));
                let objCopy = { ...e.oldData };
                keysNew.forEach((key) => {
                    type ObjectKey = keyof typeof objCopy;
                    const attrNAme = key as ObjectKey;
                    objCopy[attrNAme] = e.newData[key];
                });
                e.newData = objCopy;
                e.cancel = onChangeCellValue(e.newData, origItem, column, table, false).then(async () => {
                    await calculateRow(objCopy, column, table).then((data) => {
                        e.newData = data;
                    });
                });
            }
        };

        const renderOpenDocActColumn = (table: IDocumentTable) => {
            return (
                <Column
                    key={`openDoc`}
                    width="36px"
                    fixed={false}
                    visibleIndex={0}
                    encodeHtml={true}
                    allowResizing={false}
                    allowHiding={false}
                    allowReordering={false}
                    cssClass="dx-command-edit dx-command-edit-with-icons dx-cell-focus-disabled"
                    cellRender={(p) => {
                        return <OpenDocButton table={table} rowData={p.data} docId={docId} />;
                    }}
                />
            );
        };

        const onRowPrepared = (e: RowPreparedEvent<any, any>) => {
            // Задача EUPDEV-8613
            // Компонент MasterDetail не умеет скрывать себя для отдельных строк master-таблицы
            // Скрываем обходным путем

            if (e.rowType === 'data') {
                // Находим ячейку, содержащую стрелку раскрытия detail-части
                const row = e as any;
                const cells = row.cells as any[];
                const expanderCell = cells?.find((c) => c.column.type == 'detailExpand');
                if (!expanderCell) {
                    return;
                }

                // Определяем должна быть видима detail-часть или нет
                let visibilityPromises = table.tables.map(async (t) =>
                    evalTableFormulaValue(t.visibilityRules, null, row.data),
                );

                Promise.all(visibilityPromises).then((visibilities) => {
                    const detailIsUnvisible = visibilities.every((visible) => !visible);
                    if (detailIsUnvisible) {
                        expanderCell.cellElement.firstChild.style.display = 'none'; // скрываем элемент со стрелкой из ячейки
                        expanderCell.cellElement.style.pointerEvents = 'none'; // запрещаем ячейке реагировать на нажатия

                        // Если следующая строчка master-таблицы используется для отображения detail-части, то скрываем ее
                        const sibling = expanderCell.cellElement.parentElement?.nextElementSibling;
                        if (sibling?.classList.contains('dx-master-detail-row')) {
                            sibling.style.display = 'none';
                        }
                    } else {
                        expanderCell.cellElement.firstChild.style.display = 'block'; // показываем элемент со стрелкой из ячейки
                        expanderCell.cellElement.style.pointerEvents = 'auto'; // разрешаем ячейке реагировать на нажатия

                        // Если следующая строчка master-таблицы используется для отображения detail-части, то показываем ее
                        const sibling = expanderCell.cellElement.parentElement?.nextElementSibling;
                        if (sibling?.classList.contains('dx-master-detail-row')) {
                            sibling.style.display = 'table-row';
                        }
                    }
                });
            }
        };

        return columns ? (
            <div className="form-table-edit" data-testid={table.id ? `table-edit-${table.id}` : undefined}>
                <div className={classnames('form-table-edit-button', !showScrollbar && 'minW30')}>
                    {table?.addFormDataRows?.name && canAddRowsFormData ? (
                        <>
                            <Menu list={canAddRows ? _listMenuAddRow : _listMenuAddFormDataRow} position="top-start">
                                <div className="widgets-menu">
                                    <Button
                                        size="s"
                                        buttonType={'icon'}
                                        textColor="neutral"
                                        startAdornment={<MdAdd />}
                                        aria-label="Добавить строку"
                                    ></Button>
                                </div>
                            </Menu>
                        </>
                    ) : canAddRows ? (
                        <Button
                            size="s"
                            buttonType={'icon'}
                            textColor="neutral"
                            onClick={addRow}
                            startAdornment={<MdAdd />}
                            aria-label="Добавить строку"
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <div className="form-table-edit-content">
                    <DevExpressDataGrid
                        ref={gridRef}
                        id={`form-table-edit-${keyHash}`}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        scrolling={scrollByContent ? { useNative: false, scrollByContent: true } : { useNative: true }}
                        dataSource={dataSource}
                        onCellHoverChanged={onCellHoverChanged}
                        repaintChangesOnly={true}
                        remoteOperations={false}
                        wordWrapEnabled={table.wordWrapEnabled ?? true}
                        cacheEnabled={true}
                        onRowUpdating={onChangedRows}
                        // onRowRemoving={(e: any) => {
                        //     onTableRowDeleted(e.data, e.data, table);
                        // }}
                        onSaved={async (e) => {
                            await onSaved();
                        }}
                        onRowPrepared={onRowPrepared}
                        onSelectionChanged={(e) => {
                            const visibility = canRemoveRows && e.selectedRowKeys.length > 0;
                            deleteRowMultiRef.current?.toggleVisibility(visibility);
                        }}
                    >
                        <Editing
                            mode="cell"
                            newRowPosition="last"
                            refreshMode="repaint"
                            allowUpdating={allowUpdating}
                            allowAdding={false}
                            allowDeleting={false}
                        />
                        <ColumnChooser enabled={false} />
                        <Toolbar visible={false} />
                        <KeyboardNavigation enterKeyDirection={'row'} enterKeyAction={'startEdit'} />
                        <Sorting mode="multiple" />
                        <Paging
                            enabled={true}
                            defaultPageSize={table.pageSize && table.pageSize > 0 ? table.pageSize : 20}
                        />
                        <FilterRow showOperationChooser={true} visible={table.allowFiltersRow} />
                        {/* доп проверка на false чтобы вообще не рендерить столбец и не считать формулы из-за этого */}
                        {allowUpdating &&
                            table.editInFormFormula &&
                            table.editInFormFormula != 'false' &&
                            renderEditInFormActColumn(table)}
                        {table.previewDocByKey && renderOpenDocActColumn(table)}
                        {allowUpdating && table.copyRow && renderCopyRowColumn(table)}
                        {canRemoveRows && renderDeleteRowColumn(table)}
                        {table.rowDragging && (
                            <RowDragging
                                autoScroll={true}
                                allowDropInsideItem={false}
                                dragDirection={'vertical'}
                                boundary={`#form-table-edit-${keyHash}`}
                                allowReordering={true}
                                onReorder={async (e: any) => {
                                    let ds = e.component.getDataSource();
                                    let items = ds.items();
                                    const visibleRows = e.component.getVisibleRows();
                                    const newTasks = [...items];

                                    let toIndex = newTasks.findIndex(
                                        (item) => item['|NUM'] === visibleRows[e.toIndex].data['|NUM'],
                                    );
                                    let fromIndex = newTasks.findIndex((item) => item['|NUM'] === e.itemData['|NUM']);
                                    let selectedRowsData = e.component.getSelectedRowsData();

                                    if (fromIndex === toIndex) {
                                        return;
                                    }
                                    let currIndexRow: any;
                                    if (toIndex > fromIndex) {
                                        currIndexRow = items[toIndex];
                                    }

                                    if (selectedRowsData.length >= 1) {
                                        removeRows(items, selectedRowsData);
                                        addRowsToIdx(items, selectedRowsData, currIndexRow, toIndex);
                                    } else {
                                        items.splice(fromIndex, 1);
                                        items.splice(toIndex, 0, e.itemData);
                                    }
                                    let _store = dataSource.store() as any;
                                    let _items = (_store as any)._array;
                                    const newItems = [..._items];
                                    let store = ds.store() as ArrayStore;
                                    store.clear();

                                    _store.clear();
                                    let keyStore = store.key() as string;
                                    for (let index = 0; index < items.length; index++) {
                                        const item = items[index];

                                        let data = newItems.find((x) => x[keyStore] == item[keyStore]);

                                        await store.insert(data);
                                    }

                                    await onSaved();

                                    e.component.deselectAll();
                                }}
                                onDragStart={(e: any) => {
                                    let selectedRowKeys = e.component.getSelectedRowKeys(),
                                        selectedRowElements = getSelectedRowElements(selectedRowKeys),
                                        numSelected = selectedRowKeys.length;

                                    e.component._selectedRowElements = selectedRowElements;

                                    selectedRowElements.forEach((rowEl) => {
                                        rowEl.classList.add('dx-sortable-source');
                                    });
                                }}
                                onDragEnd={(e: any) => {
                                    e.component._selectedRowElements.forEach((rowEl: any) => {
                                        rowEl.classList.remove('dx-sortable-source');
                                    });
                                }}
                                showDragIcons={true}
                                dropFeedbackMode={'push'}
                            />
                        )}
                        {table.rowDragging && <Selection mode="multiple" showCheckBoxesMode={'none'} />}
                        {(hasEditRowMulti || canRemoveRows) && (
                            <Selection mode="multiple" showCheckBoxesMode={'always'} />
                        )}

                        {columns}
                        {table.tables?.length > 0 && renderMasterDetail(table.tables)}
                    </DevExpressDataGrid>
                </div>
                {showAddExternalRows && (
                    <DictpickerModal
                        docId={docId}
                        dictName={table?.addFormDataRows.name}
                        modalTitle={table?.addFormDataRows.name}
                        isFormData={true}
                        isMultiple={true}
                        predicatesCache={''}
                        loadMode={'all'}
                        selectableLevels={''}
                        visibleLevels={''}
                        useClientSideDataProcessing={table?.addFormDataRows.useClientSideDataProcessing}
                        getExternalDataSource={() => {
                            return [];
                        }}
                        selected={getSelectedExternalKeys()}
                        getFormValuesAsync={getFormValuesAsync!}
                        getFiltersAsync={getFiltersAsync!}
                        gridAttribute={table?.addFormDataRows.gridAttribute}
                        onSubmitModal={addExternalRows}
                        onCloseModal={() => setShowAddExternalRows(false)}
                        onValidate={onValidateExternalRows}
                        loadedDataValidators={table?.addFormDataRows.loadedDataValidators}
                    />
                )}
                {/* {rowDetailsModal} */}
            </div>
        ) : (
            <></>
        );
    },
);

export default TableData;
