import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/DocBaseActionExecutor';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';

export class OpenUrlExecutor extends DocBaseActionExecutor {
    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
    ) => {
        let serv = new DocumentExecutionService(objId);
        serv.getOpenUrlModel(action.key).then((res) => {
            let url: string = action.options.url;
            url = url.replace('{docId}', res.data.docId);
            url = url.replace('{location.origin}', window.location.origin);
            url = url.replace('{location.href}', window.location.href);
            url = url.replace('{location.hash}', window.location.hash);
            url = url.replace('{location.host}', window.location.host);
            url = url.replace('{location.hostname}', window.location.hostname);
            url = url.replace('{location.pathname}', window.location.pathname);
            url = url.replace('{location.protocol}', window.location.protocol);
            url = url.replace('{location.port}', window.location.port);
            url = url.replace('{location.search}', window.location.search);

            window.open(url, action.options.openInNewTab ? '_blank' : '_self');
            completeHandler && completeHandler(false);
        });
    };
}
