import { ActFinishType } from '@services/actions/ActivityExecutionService';
import { BaseApproveExecutor } from './BaseApproveExecutor';

export class ReworkExecutor extends BaseApproveExecutor {
    constructor() {
        super();

        this.approveModel = {
            commentIsRequired: true,
            actType: ActFinishType.Rework,
        };
    }
}
