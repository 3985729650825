import React, { useEffect, useState } from 'react';

import './TableData.scss';
import { IDocumentTable } from '@models/Forms/IForms';
import { isVisibleRowDeleteButton } from '@utils/tableHelper';

export interface RowDeleteButtonProps {
    table: IDocumentTable;
    rowData: any;
    onDelete: (rowData: any) => void;
    docId?: string;
}

const RowDeleteButton: React.FC<RowDeleteButtonProps> = ({ table, rowData, onDelete, docId }: RowDeleteButtonProps) => {
    const [isVisible, setIsVisible] = useState<boolean>(true);

    useEffect(() => {
        isVisibleRowDeleteButton(table, rowData).then((value) => {
            setIsVisible(value);
        });
    }, []);

    const onClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onDelete(rowData);
    };

    return !isVisible ? (
        <></>
    ) : (
        <a
            href="#"
            className="dx-link dx-link-delete dx-icon-trash dx-link-icon"
            onClick={onClick}
            title="Удалить строку"
            aria-label="Удалить строку"
        />
    );
};

export default RowDeleteButton;
