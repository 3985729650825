import {
    IAddRow,
    IChange,
    IChanges,
    IClearTables,
    IDeleteRow,
    IDictFilter,
    IDictFilters,
    IDocumentTable,
    IFormValues,
    ISetValue,
    IUpdateRow,
} from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from './FormulaManager';
import { UseFormReturn } from 'react-hook-form';
import { isIsoDateNoTimeString, isIsoDateString } from './documentUtils';
import { sendNotification } from '@molecules/Notifications';
import { DictionariesService } from '@/services/DictionariesService';
import { IDictionaryData } from '@/models/dictionary/IDictionaryData';
import { filterGridRowsByScript, filterResponseVoid } from './dataGridUtils';
import { round } from 'mathjs';
import Moment from 'moment';

export const clearTables = (
    clearTables: IClearTables[],
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
    rowData?: any
) => {
    clearTables?.forEach((item) => {
        if (item.table) {
            let table = fields[item.table];
            if (item.column) {
                let tableRows = formMethods.getValues(table.name as any) as any[];
                for (let index = 0; index < tableRows.length; index++) {
                    const row = tableRows[index];
                    if (item.subTable && rowData) {
                        if (row['|NUM'] === rowData['|NUM']) {
                            let subTableRows = row[item.subTable];
                            for (let subIndex = 0; subIndex < subTableRows.length; subIndex++) {
                                let columns = item.column.split(';');
                                columns.forEach((column) => {
                                    subTableRows[subIndex][column] = null;
                                });
                            }
                            row[item.subTable] = subTableRows;
                        }
                    } else {
                        let columns = item.column.split(';');
                        columns.forEach((column) => {
                            row[column] = null;
                        });
                    }
                    tableRows[index] = row;
                }
                formMethods.setValue(table.name as any, tableRows as any, { shouldDirty: true });
            } else {
                if (item.subTable && rowData) {
                    const tableRows = formMethods.getValues(table.name) as any[];
                    for (let index = 0; index < tableRows.length; index++) {
                        const row = tableRows[index];
                        if (row['|NUM'] === rowData['|NUM']) {
                            rowData[item.subTable] = [];
                            tableRows[index] = rowData;
                            formMethods.setValue(table.name, tableRows, { shouldDirty: false});
                            break;
                        }
                    }
                } else {
                    formMethods.setValue(table.name as any, [] as any, { shouldDirty: true });
                }
            }
        }
    });
};

const getFormValues = (
    formdataParams: IFormValues,
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
) => {
    const map = {} as Record<string, any>;
    if (formdataParams && formdataParams != null) {
        for (const ele of formdataParams?.formValue!) {
            let field = fields[ele.attr];
            let fname = ('fields.[' + field.index + '].value') as any;
            let val = formMethods.getValues(fname);
            if (ele.column) {
                if (ele.function === '{join}') {
                    map[ele.key] = (val as [])?.map((item: any) => `'${item[ele.column]}'`).join(',') ?? val;
                } else {
                    map[ele.key] = (val as [])?.map((item: any) => item[ele.column]) ?? val;
                }
            } else {
                map[ele.key] = val;
            }
        }
    }
    return JSON.stringify(map);
};

const getFiltersAsync = async (
    filters: IDictFilters,
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
) => {
    let result = {} as IDictFilter;
    const map = {} as Record<string, any>;
    if (filters && filters != null) {
        for (const ele of filters.filter) {
            let conditionMng = new FormulaManager(ele.condition);
            conditionMng.Init(fields, formMethods);
            if (await conditionMng.EvalFormulaValues(true, true)) {
                result.condition = ele.condition;
                if (ele.filter) {
                    let filterMng = new FormulaManager(ele.filter);
                    filterMng.Init(fields, formMethods);
                    result.filter = (await filterMng.ReplaceFormulaValues(true, true))!;
                }
                if (ele.script) {
                    let scriptMng = new FormulaManager(ele.script);
                    scriptMng.Init(fields, formMethods);
                    result.script = (await scriptMng.ReplaceFormulaValues(true, true))!;
                }
            }
        }
    }
    return result;
};

export const addRow = async (
    addRows: IAddRow[],
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
    rowData?: any,
    rowOldData?: any,
) => {
    if (addRows) {
        for (let index = 0; index < addRows.length; index++) {
            const item = addRows[index];
            if (item.table) {
                let table = fields[item.table];

                let rowcount = 1;
                if (item.countRows) {
                    let count = new FormulaManager(item.countRows);
                    count.Init(fields, formMethods);
                    rowcount = await count.EvalFormulaValues(true, false, undefined);
                }
                let tableRows = formMethods.getValues(table.name as any) as any[];
                let exRows: any[] = [];
                if (tableRows == undefined || tableRows == null) tableRows = [];
                if (item.externalDataSource != null) {
                    let params =
                        '?formValues=' +
                        getFormValues(item.externalDataSource.formDataSource.formValues, formMethods, fields);

                    let response = await DictionariesService.getGridFormdataItems(
                        '-1',
                        item.externalDataSource.formDataSource.dictName,
                        params,
                    );
                    if (
                        item.externalDataSource.formDataSource.filters &&
                        item.externalDataSource.formDataSource.filters.filter
                    ) {
                        let filters = await getFiltersAsync(
                            item.externalDataSource.formDataSource.filters,
                            formMethods,
                            fields,
                        );
                        let _data: IDictionaryData[] = [];

                        _data = filterResponseVoid(response.data.data, filters);
                        if (filters?.script) {
                            _data = filterGridRowsByScript(_data, filters?.script);
                        }
                        exRows = _data;
                        rowcount = _data.length;
                    } else {
                        rowcount = response.data.totalCount;
                        exRows = response.data.data;
                    }
                }

                for (let indexRow = 0; indexRow < rowcount; indexRow++) {
                    const obj: Record<string, any> = {};
                    if (exRows.length > 0) {
                        let exRowData: Record<string, any> = {};
                        exRows[indexRow].fields.forEach((item: any) => {
                            exRowData['|' + item.name] = item.value;
                        });
                        rowData = exRowData;
                    }
                    for (let index = 0; index < item.setValues.sets.length; index++) {
                        const element = item.setValues.sets[index];
                        let objCopy = { ...element };
                        let formula = element.attr.replace(/\{index\}/g, indexRow.toString());
                        objCopy.attr = replaceTagRow(objCopy.attr, formula, 'currentRow', rowData);
                        objCopy.attr = replaceTagRow(objCopy.attr, objCopy.attr, 'currentOldRow', rowOldData);

                        obj[element.key] = await GetValueForSetValue(objCopy, undefined, fields, rowData, formMethods);
                    }

                    tableRows.push(obj);
                }
                formMethods.setValue(table.name as any, tableRows as any, { shouldDirty: true });
            }
        }
    }
};

const replaceTagRow = (rule: string, formula: string, tag: string, row?: any) => {
    const regex = tag === 'currentRow' ? /{\[currentRow\](?<column>.*?)\}/gm : /{\[currentOldRow\](?<column>.*?)\}/gm;
    let m;
    while ((m = regex.exec(rule)) !== null) {
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        let match = m[0];
        let columnCurrentRow = m.groups?.column;
        if (columnCurrentRow) {
            if (row[columnCurrentRow]) {
                formula = formula.replace(match, row[columnCurrentRow]);
            } else {
                formula = formula.replace(match, '0');
            }
        }
    }
    return formula;
};

const processingUpdateRow = async (
    tableRows: any[],
    item: IUpdateRow,
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
    rowData?: any,
    rowOldData?: any,
) => {
    let newArr: any[] = [];

    for (let index = 0; index < tableRows.length; index++) {
        const obj = tableRows[index];
        let objCopy = { ...obj };
        newArr.push(objCopy);
    }

    for (let indexRow = 0; indexRow < newArr.length; indexRow++) {
        let row = newArr[indexRow];

        let checkResult = true;
        if (item.filter) {
            let filter = item.filter;
            filter = replaceTagRow(filter, item.filter, 'currentRow', rowData);
            filter = replaceTagRow(filter, filter, 'currentOldRow', rowOldData);

            let filterMng = new FormulaManager(filter);
            filterMng.Init(fields, formMethods);
            let coll = filterMng.GetFields();
            let values: any[] = [];
            coll.forEach((field) => {
                let val: any = undefined;
                if (row && row[field]) {
                    val = row[field];
                    values.push(val);
                }
            });
            checkResult = await filterMng.EvalFormulaValues(false, false, values);
        }

        if (checkResult) {
            for (let index = 0; index < item.setValues.sets.length; index++) {
                const set = item.setValues.sets[index];
                let objCopy = { ...set };
                let formula = set.attr.replace(/\{index\}/g, indexRow.toString());
                objCopy.attr = replaceTagRow(set.attr, formula, 'currentRow', rowData);
                objCopy.attr = replaceTagRow(objCopy.attr, objCopy.attr, 'currentOldRow', rowOldData);

                newArr[indexRow][set.key] = await GetValueForSetValue(objCopy, undefined, fields, row, formMethods);
            }
        }
    }
    return newArr;
};
const processingDeleteRow = async (
    tableRows: any[],
    item: IDeleteRow,
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
) => {
    let newArr: any[] = [];

    for (let index = 0; index < tableRows.length; index++) {
        const obj = tableRows[index];
        let objCopy = { ...obj };

        let checkResult = false;
        if (item.filter) {
            let filter = item.filter;
            filter = replaceTagRow(filter, item.filter, 'currentRow', objCopy);
            let filterMng = new FormulaManager(filter);
            filterMng.Init(fields, formMethods);
            let coll = filterMng.GetFields();
            let values: any[] | undefined = undefined;
            if (formMethods && objCopy && coll.length > 0) {
                values = GetFormulaValues(coll, undefined, fields, objCopy, formMethods);
            }
            checkResult = await filterMng.EvalFormulaValues(true, true, values);
        }
        if (!checkResult) {
            newArr.push(objCopy);
        }
    }

    return newArr;
};

const updateRowsGroup = async (
    updateRowsGroup: any,
    tableRows: any,
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
    rowData?: any,
    rowOldData?: any,
) => {
    let result: any;
    let rows = [...tableRows];
    for (let index = 0; index < updateRowsGroup.length; index++) {
        const item = updateRowsGroup[index];
        if (item.table) {
            if (rows != undefined && rows != null && rows.length > 0) {
                if (item.subTable) {
                    let parent: any[] = [];

                    for (let index = 0; index < rows.length; index++) {
                        const obj = rows[index];
                        let objCopy = { ...obj };
                        parent.push(objCopy);
                    }
                    for (let indexRow = 0; indexRow < parent.length; indexRow++) {
                        let row = parent[indexRow];
                        let subTableRows = row[item.subTable];
                        if (subTableRows != undefined) {
                            parent[indexRow][item.subTable] = await processingUpdateRow(
                                subTableRows,
                                item,
                                formMethods,
                                fields,
                                rowData,
                                rowOldData,
                            );
                        }
                    }
                    result = parent;
                } else {
                    result = await processingUpdateRow(rows, item, formMethods, fields, rowData, rowOldData);
                }
                rows = result;
            }
        }
    }
    return result;
};

export const updateRow = async (
    updateRows: IUpdateRow[],
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
    rowData?: any,
    rowOldData?: any,
) => {
    if (updateRows) {
        let groups = updateRows.reduce(
            (entryMap, e) => entryMap.set(e.table, [...(entryMap.get(e.table) || []), e]),
            new Map(),
        );

        groups.forEach(async (rowsGroup, key, map) => {
            if (key) {
                let table = fields[key];
                let tableRows = formMethods.getValues(table.name as any) as any[];
                let result: any;
                result = await updateRowsGroup(rowsGroup, tableRows, formMethods, fields, rowData, rowOldData);
                formMethods.setValue(table.name as any, result, {
                    shouldDirty: true,
                });
            }
        });
    }
};
export const deleteRow = async (
    deleteRows: IDeleteRow[],
    formMethods: UseFormReturn<any>,
    fields: Record<string, IFieldElem>,
) => {
    if (deleteRows) {
        for (let index = 0; index < deleteRows.length; index++) {
            const item = deleteRows[index];
            if (item.table) {
                let table = fields[item.table];
                let tableRows = formMethods.getValues(table.name as any) as any[];

                if (tableRows != undefined && tableRows != null && tableRows.length > 0) {
                    let result: any;
                    if (item.subTable) {
                        let parent: any[] = [];

                        for (let index = 0; index < tableRows.length; index++) {
                            const obj = tableRows[index];
                            let objCopy = { ...obj };
                            parent.push(objCopy);
                        }
                        for (let indexRow = 0; indexRow < parent.length; indexRow++) {
                            let row = parent[indexRow];
                            let subTableRows = row[item.subTable];
                            parent[indexRow][item.subTable] = await processingDeleteRow(
                                subTableRows,
                                item,
                                formMethods,
                                fields,
                            );
                        }
                        result = parent;
                    } else {
                        result = await processingDeleteRow(tableRows, item, formMethods, fields);
                    }

                    formMethods.setValue(table.name as any, result, { shouldDirty: true });
                }
            }
        }
    }
};

export const GetValueForSetValue = async (
    item: ISetValue,
    tableName: any,
    data: Record<string, IFieldElem>,
    rowData?: any,
    formMethods?: UseFormReturn<any>,
) => {
    let val: any = undefined;
    let formulaMng = new FormulaManager(item.attr);
    formulaMng.Init(data, formMethods);
    let coll = formulaMng.GetFields();
    let values: any[] | undefined = undefined;
    if (formMethods && rowData && coll.length > 0 && !coll.every((element) => element === undefined)) {
        values = GetFormulaValues(coll, tableName, data, rowData, formMethods);
    }
    switch (item.type) {
        case 'text':
            val = await formulaMng.ReplaceFormulaValues(true, true, values, true);
            break;
        case 'json':
            val = JSON.parse(item.attr);
            break;
        case 'calc': {
            try {
                let valCalc = await formulaMng.EvalFormulaValues(true, true, values);
                if (isNaN(valCalc) && typeof valCalc !== 'string') {
                    val = 0;
                } else {
                    if (typeof valCalc == 'string' && valCalc.indexOf(',')) {
                        valCalc = valCalc.replace(',', '.');
                    }
                    val = +valCalc;
                    if (item.floatPoints) {
                        val = round(val, item.floatPoints);
                    }
                }
            } catch (error) {
                console.log(error);
                val = 0;
            }
            break;
        }
        case 'date': {
            try {
                val = await formulaMng.EvalFormulaValues(true, true, values);
                try {
                    let parseVal = new Date(val);
                    if (!isNaN(parseVal.getTime()) && parseVal.getTime() > 0) {
                        val = parseVal;
                    } else {
                        throw new Error('parse Date');
                    }
                } catch (error) {
                    let parts = val.split(' ');
                    if (parts.length > 1) {
                        val = Moment(val, 'DD.MM.YYYY hh.mm.ss').toDate();
                    } else {
                        if (Moment(val, 'DD.MM.YYYY').isValid()) {
                            val = Moment(val, 'DD.MM.YYYY').toDate();
                        } else {
                            if (Moment(val).isValid()) {
                                val = Moment(val).toDate();
                            } else {
                                val = null;
                            }
                        }
                    }
                }
            } catch (error) {
                val = null;
            }
            break;
        }
        default:
            val = item.attr;
            break;
    }
    return val;
};

export const GetFormulaValues = (
    valuesFields: string[],
    tableName: any,
    data: Record<string, IFieldElem>,
    rowData?: any,
    formMethods?: UseFormReturn<any>,
    valField?: any,
) => {
    let values: any[] = [];
    if (formMethods) {
        valuesFields.forEach((field) => {
            let val: any = undefined;
            if (field === "'{value}'") {
                val = valField;
            } else if (
                field.includes('|Document') ||
                field.startsWith('#links') ||
                (!field.startsWith('|') && !field.includes('{')) ||
                rowData === undefined
            ) {
                val = formMethods.getValues(data[field].name);
                if (rowData != undefined && field == tableName) {
                    if (val == undefined) {
                        val = [];
                    }
                    let arr = val as any[];
                    let indexRow = arr.findIndex((f) => {
                        return f['|NUM'] == rowData['|NUM'];
                    });
                    if (indexRow == -1) {
                        arr.push(rowData);
                    } else {
                        arr[indexRow] = rowData;
                    }
                    val = arr;
                }
            } else if (field.startsWith('|')) {
                val = rowData[field];
            }
            values?.push(val);
        });
    }
    return values;
};

export const checkCondition = async (
    condition: string,
    value: any,
    fieldName: string,
    isEdit: boolean,
    isNew: boolean,
    data: Record<string, IFieldElem>,
    formMethods?: UseFormReturn<any>,
    rowData?: any,
    rowOldData?: any,
) => {
    let val = value;
    if (val instanceof Date) {
        val = val.getTime().toString();
    } else {
        if (isIsoDateString(val) || isIsoDateNoTimeString(val)) {
            let valDate = new Date(val); // Moment(value).toDate();
            val = valDate.getTime().toString();
        }
    }

    let rule = condition; //.replace(/\{value\}/g, val);
    rule = replaceTagRow(rule, rule, 'currentRow', rowData);
    rule = replaceTagRow(rule, rule, 'currentOldRow', rowOldData);
    if (rule.indexOf('{value}') > -1) {
        rule = rule.replaceAll('{value}', val);
    }
    let formulaManager = new FormulaManager(rule);
    formulaManager.Init(data, formMethods);
    let valuesFields = formulaManager.GetFields();

    let values: any[] | undefined = undefined;
    if (formMethods && valuesFields.length > 0) {
        values = GetFormulaValues(valuesFields, fieldName, data, rowData, formMethods, val);
    }

    return !!(await formulaManager.EvalFormulaValues(isEdit, isNew, values));
};

export const executeChanges = async (
    change: IChange,
    data: Record<string, IFieldElem>,
    onSave: (item: ISetValue, table?: IDocumentTable, rowData?: any) => void,
    setError: (errors?: string[]) => void,
    table?: IDocumentTable,
    formMethods?: UseFormReturn<any>,
    rowData?: any,
    rowOldData?: any,
) => {
    if (change?.setValues?.sets) {
        for (let index = 0; index < change?.setValues?.sets.length; index++) {
            const element = change?.setValues?.sets[index];
            let objCopy = { ...element };
            let formula = element.attr.replace(/\{index\}/g, index.toString());
            objCopy.attr = replaceTagRow(objCopy.attr, formula, 'currentRow', rowData);
            objCopy.attr = replaceTagRow(objCopy.attr, objCopy.attr, 'currentOldRow', rowOldData);

            await onSave(objCopy, table, rowData);
        }
    }
    clearTables(change?.setValues?.clearTables!, formMethods!, data, rowData);
    await deleteRow(change?.setValues?.deleteRows!, formMethods!, data);
    await addRow(change?.setValues?.addRows!, formMethods!, data, rowData, rowOldData);
    await updateRow(change?.setValues?.updateRows!, formMethods!, data, rowData, rowOldData);
    if (change?.showErrors) {
        let messages: string[] = [];
        change?.showErrors.messages.forEach((msg) => {
            messages.push(msg.value);
        });
        setError(messages);
    }
    if (change?.showNotifications) {
        for (let index = 0; index < change?.showNotifications.notifications.length; index++) {
            const msg = change?.showNotifications.notifications[index];
            let txt = await GetTextForNotification(msg.text, table?.key, data, rowData, formMethods);
            if (txt) {
                sendNotification(
                    {
                        title: msg.title,
                        message: txt,
                        variant: msg.type,
                    },
                    msg.disabledAutoHide ? 100000 : undefined,
                );
            }
        }
    }
};

export const GetTextForNotification = async (
    text: string,
    tableName: any,
    data: Record<string, IFieldElem>,
    rowData?: any,
    formMethods?: UseFormReturn<any>,
) => {
    if (text.indexOf('{') > -1 || text.indexOf('(') > -1) {
        let formulaMng = new FormulaManager(text);
        formulaMng.Init(data, formMethods);
        let coll = formulaMng.GetFields();
        let values: any[] | undefined = undefined;
        if (formMethods && rowData && coll.length > 0 && !coll.every((element) => element === undefined)) {
            values = GetFormulaValues(coll, tableName, data, rowData, formMethods);
        }

        let result = await formulaMng.ReplaceFormulaValues(true, true, values, true);
        return result;
    } else {
        return text;
    }
};

export const sortChanges = (changes: IChange[]) => {
    let newArr: IChange[] = [];
    changes.forEach((obj: any) => {
        const objCopy = { ...obj };
        newArr.push(objCopy);
    });
    return newArr.sort((a, b) => a.index > b.index || a.condition == null ? 1 : -1);
};

export const ChangesFieldValue = async (
    changes: IChanges,
    value: any,
    fieldName: string,
    data: Record<string, IFieldElem>,
    isEdit: boolean,
    isNew: boolean,
    onSave: (item: ISetValue, table?: IDocumentTable, rowData?: any, value?: any) => void,
    setError: (errors?: string[]) => void,
    table?: IDocumentTable,
    rowData?: any,
    formMethods?: UseFormReturn<any>,
    rowOldData?: any,
) => {
    if (!changes || changes.change.length === 0) {
        return;
    }

    const sortedChanges = sortChanges(changes.change);
    for (let index = 0; index < sortedChanges.length; index++) {
        const change = sortedChanges[index];
        let needExecute = true;
        let subChanges = change.changes;
        if (change.condition) {
            needExecute = await checkCondition(change.condition, value, fieldName, isEdit, isNew, data, formMethods, rowData, rowOldData);
            if (!needExecute) {
                subChanges = change.elseChanges?.changes;
            }
        }
        if (needExecute) {
            await executeChanges(change, data, onSave, setError, table, formMethods, rowData, rowOldData);
        }
        if (subChanges?.change.length > 0) {
            await ChangesFieldValue(
                change.changes,
                value,
                fieldName,
                data,
                isEdit,
                isNew,
                onSave,
                setError,
                table,
                rowData,
                formMethods,
                rowOldData
            );
        }
    }
};
