import './AttachFilesFromExistingDocsExecutor.scss';
import React, { useEffect, useRef, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { MdClose } from 'react-icons/md';
import { IBaseAction } from '@models/actions/IBaseAction';
import { classnames } from '@utils/classnames';
import { DocBaseActionExecutor } from '@utils/actions/DocBaseActionExecutor';
import Preloader from '@atoms/Preloader';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { Column, DataGrid, Selection } from 'devextreme-react/data-grid';
import { ModalSize } from '@atoms/Modal/Modal';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { IAttachListItemModel } from '@/models/attaches/IAttachListItemModel';
import { IDocumentData } from '@/models/document/IDocumentData';
import { useActions } from '@hooks/useActions';

export class AttachFilesFromExistingDocsExecutor extends DocBaseActionExecutor {
    private _modalProps?: IAttachFilesFromExistingDocsExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            action: action,
            completeHandler: completeHandler,

            payloadData: action.options.actionData,
            docData: action.options.docData,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <AttachFilesFromExistingDocsExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface IAttachFilesFromExistingDocsExecutorProps {
    docId: string;
    action: IBaseAction;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;

    payloadData: any;
    docData: IDocumentData;
}

const AttachFilesFromExistingDocsExecutorModal: React.FC<IAttachFilesFromExistingDocsExecutorProps> = (
    props: IAttachFilesFromExistingDocsExecutorProps,
) => {
    const [isBusy, setIsBusy] = useState<boolean>();
    const [filesToSelect, setFilesToSelect] = useState<IAttachListItemModel[]>();
    const [checkedAttach, setCheckedAttach] = useState<string[]>([]);

    const { setPartsVersionToAttach } = useActions();

    useEffect(() => {
        let service = new AttachActionsService(props.docId, '-1');
        if (props.payloadData.docIds) {
            setIsBusy(true);
            let ids = props.payloadData.docIds.split(',');
            service.getDocsAttaches(ids).then((res) => {
                setFilesToSelect(res.data);
                setIsBusy(false);
            });
        }
    }, []);

    const onSelectionChanged = (e: any) => {
        if (e.currentSelectedRowKeys.length > 0) {
            let data = e.selectedRowsData.pop();
            let key = e.selectedRowKeys.pop();
            e.component.deselectRows(e.selectedRowKeys);
            //setPartsVersionToAttach();
            setCheckedAttach([key.docIdAndPartNum]);
        } else if (e.selectedRowKeys.length == 0) {
            //setPartsVersionToAttach([]);
            setCheckedAttach([]);
        }
    };

    const onRowClick = (e: any) => {
        const keys = e.component.getSelectedRowKeys();
        const index = keys.indexOf(e.key);
        if (index > -1) {
            keys.splice(index, 1);
        } else {
            keys.splice(0, keys.length);
            keys.push(e.key);
        }
        e.component.selectRows(keys);
    };

    const gridRef = useRef<DataGrid>(null);
    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>{props.action.displayName}</div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {isBusy ? (
                <Preloader size="m" />
            ) : (
                <div>
                    <DevExpressDataGrid
                        key="abooksearchGrid"
                        showRowLines={true}
                        columnAutoWidth={true}
                        columnMinWidth={30}
                        dataSource={filesToSelect}
                        remoteOperations={true}
                        hoverStateEnabled={true}
                        columnHidingEnabled={false}
                        showColumnHeaders={true}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        ref={gridRef}
                        onSelectionChanged={onSelectionChanged}
                        onCellHoverChanged={onCellHoverChanged}
                        onRowClick={onRowClick}
                    >
                        <Selection mode="multiple" allowSelectAll={false} selectAllMode="page" />

                        <Column
                            key={`col_attachName`}
                            allowFiltering={true}
                            caption={'Файл'}
                            dataField={'attachName'}
                            dataType={'string'}
                            visible={true}
                            allowSorting={false}
                            filterOperations={['contains']}
                            showEditorAlways={false}
                            width={'*'}
                        ></Column>

                        <Column
                            key={`col_docName`}
                            allowFiltering={true}
                            caption={'Документ'}
                            dataField={'docName'}
                            dataType={'string'}
                            visible={true}
                            allowSorting={false}
                            filterOperations={['contains']}
                            showEditorAlways={false}
                        ></Column>

                        <Column
                            key={`col_isManualCreated`}
                            allowFiltering={true}
                            caption={'Создан вручную'}
                            dataField={'isManualCreated'}
                            dataType={'boolean'}
                            visible={true}
                            allowSorting={false}
                            filterOperations={['contains']}
                            showEditorAlways={false}
                            width={'*'}
                        ></Column>
                    </DevExpressDataGrid>

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                size="s"
                                aria-label={props.okButtonText}
                                onClick={() => {
                                    if (props.completeHandler) {
                                        setPartsVersionToAttach(checkedAttach);
                                        props.completeHandler(true);
                                    }
                                }}
                            >
                                {props.okButtonText}
                            </Button>

                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
