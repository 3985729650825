import { ActFinishType } from '@services/actions/ActivityExecutionService';
import { BaseWithSignExecutor } from './BaseWithSignExecutor';

export class ApproveWithSignExecutor extends BaseWithSignExecutor {
    constructor() {
        super();

        this.approveModel = {
            commentIsRequired: false,
            actType: ActFinishType.Approve,
        };
    }
}
