import './TreeSettingsButton.scss';
import React, { FC, useMemo, useRef } from 'react';
import Button from '@atoms/Button';
import Menu from '@atoms/Menu';
import { MdAddchart, MdMenu, MdRefresh, MdSettings } from 'react-icons/md';
import { IListElement } from '@/types';
import { IMenuRefActions } from '@atoms/Menu/Menu';

export interface ITreeSettingsButtonProps {
    onClickStateChooser: () => void;
    onClickRefresh: () => void;
    onClickColumnChooser: () => void;
}

const TreeSettingsButton: FC<ITreeSettingsButtonProps> = ({
    onClickStateChooser,
    onClickRefresh,
    onClickColumnChooser,
}: ITreeSettingsButtonProps) => {
    const mouseOnComponent = useRef(true);
    const menuRef = useRef<IMenuRefActions>(null);
    const menuList = useMemo<IListElement[]>(() => {
        return [
            {
                label: (
                    <div className="tree-settings__menu-item">
                        <MdMenu size="24" />
                        <div className="tree-settings__menu-item-label">Список состояний</div>
                    </div>
                ),
                separated: false,
                value: '1',
                handler: onClickStateChooser,
            },
            {
                label: (
                    <div className="tree-settings__menu-item">
                        <MdRefresh size="24" />
                        <div className="tree-settings__menu-item-label">Сбросить состояние</div>
                    </div>
                ),
                separated: true,
                value: '2',
                handler: onClickRefresh,
            },
            {
                label: (
                    <div className="tree-settings__menu-item">
                        <MdAddchart size="24" />
                        <div className="tree-settings__menu-item-label">Выбор столбцов</div>
                    </div>
                ),
                separated: true,
                value: '3',
                handler: onClickColumnChooser,
            },
        ] as IListElement[];
    }, [onClickColumnChooser, onClickRefresh, onClickStateChooser]);

    return (
        <div
            className="tree-settings"
            onMouseEnter={() => {
                mouseOnComponent.current = true;
            }}
            onMouseLeave={() => {
                mouseOnComponent.current = false;
                setTimeout(() => {
                    if (!mouseOnComponent.current) menuRef.current?.close();
                }, 1000);
            }}
        >
            <Menu menuRef={menuRef} list={menuList} position="bottom-end">
                <div className="tree-settings__menu">
                    <Button buttonType="text" textColor="neutral" size="s" startAdornment={<MdSettings size="24" />} />
                </div>
            </Menu>
        </div>
    );
};

export default TreeSettingsButton;
