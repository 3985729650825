import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { ActivityActionsExecFactory } from '@utils/actions/ActivityActionsExecFactory';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import { ActivityLocationTypes } from './ActivityLocationTypes';
import { ActionAccessibleCheckResult } from '@models/actions/ActionAccessibleCheckResult';
import { IActionService } from '@services/actions/IActionService';

export class ActivityActionsService implements IActionService<IBaseAction> {
    private _location?: ActivityLocationTypes;

    constructor(location?: ActivityLocationTypes) {
        this._location = location;
    }

    public getActionExecutor(act: IBaseAction): IActionExecutor {
        return ActivityActionsExecFactory.GetActionExecutor(act)!;
    }

    //Получает все действия для активити
    async fetchActions(actId: string): Promise<AxiosResponse<IActivityAction[]>> {
        return $api.get<IActivityAction[]>(`tasks/getActionActivities/${actId}?location=${this._location}`);
    }

    //Получает все действия для группы активити
    async fetchGroupActions(actIds: string): Promise<AxiosResponse<IActivityAction[]>> {
        return $api.get<IActivityAction[]>(`tasks/getGroupActionActivities/${actIds}?location=${this._location}`);
    }

    async checkActionAccessibility(
        activityId: string | undefined,
        actionKey: string,
    ): Promise<AxiosResponse<ActionAccessibleCheckResult>> {
        return $api.get<ActionAccessibleCheckResult>(
            `activityActions/checkActionAccessibility/${actionKey}/${activityId ?? ''}`,
        );
    }
}
