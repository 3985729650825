import axios, { InternalAxiosRequestConfig } from 'axios';
import history from '@/router/history';
import { AuthSlice } from '../store/reducers/AuthReducer';
import { privateRoutes, RouteNames } from '@/router';
import { matchPath } from 'react-router-dom';
import { ConfigService } from '@/configuration/services/configService';

const config = ConfigService.get();
export const API_URL: string = config.application.apiUrl;
const withCredentials: boolean = config.application.withCredentials;
const redirectToLogin: boolean = config.application.redirectToLogin;

let store: any;

export const apiStore = (_store: any) => {
    store = _store;
};

const $api = axios.create({
    withCredentials: withCredentials,
    baseURL: API_URL,
});

$api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    if (!config || !config.headers) return Promise.resolve(config);
    const token = localStorage.getItem('frontAccessToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return Promise.resolve(config);
});

$api.interceptors.response.use(
    (originalResponse) => {
        return originalResponse;
    },
    async (error) => {
        const originalRequest = error.config;

        /*if (error.response.data.status === 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                const refreshToken = localStorage.getItem('frontRefreshToken');
                const response = await AuthService.refreshToken(refreshToken);

                localStorage.setItem('frontAccessToken', response.data.accessToken);
                return $api.request(originalRequest);
            } catch (e) {
                console.log(e);
            }
        }*/

        if (!originalRequest || !originalRequest.retry) {
            if (
                error.response !== undefined &&
                error.response.status === 401 &&
                error.config &&
                !error.config._isRetry
            ) {
                localStorage.removeItem('frontAccessToken');
                store.dispatch(AuthSlice.actions.successLogout());

                // сохраняем текущий локейшн для возврата в будущем
                const path = window.location.pathname;
                const search = window.location.search;
                const hash = window.location.hash;

                // Переходим на страницу логина для приватных маршрутов или по флагу всегда
                let isPrivateRoute = privateRoutes.some((r) => {
                    return matchPath(r.path, path) !== null;
                });
                if (redirectToLogin || isPrivateRoute) {
                    // передаем текущий локейшн для возврата после логина
                    history.push(RouteNames.LOGIN, {
                        fromPathname: path,
                        fromSearch: search,
                        fromHash: hash,
                    });
                }

                return Promise.resolve();
            }

            if (axios.isCancel(error)) {
                // при отмене запроса возвращаем пустую ошибку
                throw '';
            }

            if (
                error.response !== undefined &&
                error.response.data !== undefined &&
                (error.response.status === 400 || error.response.status === 500)
            ) {
                let msg: string[] = [];
                let errData = error.response.data;

                // если в ответе Exception
                if (errData.InnerExceptions) {
                    let errors = errData.InnerExceptions as any[];
                    errors.forEach((error) => {
                        if (error.message) {
                            msg.push(error.message);
                        } else if (error.Message) {
                            msg.push(error.Message);
                        }
                    });

                    if (msg.length > 0) throw msg;
                } else if (errData.errors) {
                    let message = errData.Message;
                    if (message === undefined) {
                        let txt = '';
                        const keysObj = Object.keys(errData.errors);
                        for (let index = 0; index < keysObj.length; index++) {
                            let keyName = keysObj[index];
                            let errTxt = errData.errors[keyName];
                            txt += keyName + ' ' + errTxt;
                        }

                        if (errData.title) message = errData.title + ' ' + txt;
                        else message = txt;
                    }
                    if (message) throw [message];
                }

                if (errData.message !== undefined) {
                    if (errData.message !== undefined && errData.innerException.Message !== undefined)
                        throw [errData.message, errData.innerException.Message];

                    if (errData.message !== undefined && errData.innerException.message !== undefined)
                        throw [errData.message, errData.innerException.message];

                    throw [errData.message];
                }

                if (errData.Message !== undefined) throw [errData.Message];

                if (errData.title !== undefined) throw [errData.title];

                if (typeof errData === 'string') throw [errData.toString()];
            }

            console.error(error);

            throw 'Ошибка получения данных с сервера, обновите страницу.';
        }

        originalRequest.retry -= 1;
        const delayRetryRequest = new Promise((resolve) => {
            setTimeout(() => {
                console.log('retry the request', originalRequest.url);
                Promise.resolve();
            }, originalRequest.retryDelay || 1000);
        });
        return delayRetryRequest.then(() => $api.request(originalRequest));
    },
);

export default $api;
