import { ActFinishType } from '@services/actions/ActivityExecutionService';
import { BaseWithSignExecutor } from './BaseWithSignExecutor';

export class ReworkWithSignExecutor extends BaseWithSignExecutor {
    constructor() {
        super();

        this.approveModel = {
            commentIsRequired: true,
            actType: ActFinishType.Rework,
        };
    }
}
