import { MonoTypeOperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { IFormattedDate, Size } from '@/types';
import history from '@/router/history';
import { months } from '@utils/helpersDatePicker';

const monthsShort = ['янв', 'фев', 'мар', 'апр', 'май', 'июнь', 'июль', 'авг', 'сент', 'окт', 'нояб', 'дек'];
const week = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];

/**
 * Функция для добавления окончаний к месяцам
 * @param value - имя месяца
 *
 */
export const getMonthName = (value: string) => {
    if (value) {
        const arr: string[] = value.split('');
        let result;

        if (months.ru.indexOf(value) !== -1) {
            arr.splice(arr.length - 1, 1, 'я');
            result = arr.join('');
            return result;
        }

        arr.push('а');
        result = arr.join('');
        return result;
    }

    return '';
};
export const getValueByPath = (object: any, path: string) => {
    let parts = path.split('.');
    let current = object;
    parts.forEach((element) => {
        if (element.indexOf('[') > -1) {
            let index = +element.replace('[', '').replace(']', '');
            current = current[index];
        } else {
            current = current[element];
        }
    });
    return current;
};

export const getDiffObj = (obj1: any, obj2: any) => {
    return Object.entries(obj2).reduce((diff, [key, value]) => {
        // Check if the property exists in obj2.
        if (obj1.hasOwnProperty(key)) {
            const val = obj1[key];
            const areObjects = isObject(val) && isObject(value);
            // Check if obj1's property's value is different from obj2's.
            if ((!areObjects && val !== value) || (areObjects && !deepEqual(val, value))) {
                return {
                    ...diff,
                    [key]: value,
                };
            }
        } else {
            return {
                ...diff,
                [key]: value,
            };
        }

        // Otherwise, just return the previous diff object.
        return diff;
    }, {});
};

export const deepEqual = (object1: any, object2: any) => {
    if ((object1 == undefined && object2 != undefined) || (object1 != undefined && object2 == undefined)) {
        return false;
    }
    if (object1 == undefined && object2 == undefined) {
        return true;
    }
    const sysNames = ['_dataByKeyMap', '_dataByKeyMapLength'];

    const keys1 = Object.keys(object1).filter((x) => {
        return sysNames.indexOf(x) === -1;
    });
    const keys2 = Object.keys(object2).filter((x) => {
        return sysNames.indexOf(x) === -1;
    });
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }
    return true;
};
function isObject(object: any) {
    return object != null && typeof object === 'object';
}

/** Добавить нули */
export const addLeadingZeros = (number: number, length = 2) => {
    return ('0'.repeat(length) + number).slice(-length);
};

/**
 * Функция для форматирования даты
 *  @param date - дата, которую нужно преобразовать
 *
 */
export const formatDate = (date: string | number | undefined): IFormattedDate => {
    if (!date) {
        date = Date.now();
    }

    const tempDate = new Date(date);
    const month = addLeadingZeros(tempDate.getMonth() + 1);
    const monthLong = getMonthName(months.ru[tempDate.getMonth()]);
    const monthName = months.ru[tempDate.getMonth()];
    const monthShort = monthsShort[tempDate.getMonth()];

    const dayOfMonth = addLeadingZeros(tempDate.getDate());
    const dayOfWeek = week[tempDate.getDay()];
    const tempHour = tempDate.getHours();
    const hour = addLeadingZeros(tempHour);
    const tempMinutes = tempDate.getMinutes();
    const minutes = addLeadingZeros(tempMinutes);
    const year = tempDate.getFullYear();

    return {
        month,
        monthLong,
        monthShort,
        monthName,
        dayOfMonth,
        dayOfWeek,
        hour,
        minutes,
        year,
        date: `${dayOfMonth}.${month}.${year}`,
        time: `${hour}:${minutes}`,
    };
};

/** Преобразовать dd.mm.yyy в Date */
export const stringToDate = (s: string): Date => {
    const d = new Date();

    if (!s) {
        return d;
    }

    const formatToday = formatDate(d.getTime()).date.split('.');

    let [dd, mm, yyyy] = s.slice(0, 10).split('.');
    dd = dd.includes('_') ? formatToday[0] : dd;
    mm = mm.includes('_') ? formatToday[1] : mm;
    yyyy = yyyy.includes('_') ? formatToday[2] : yyyy;
    return new Date(+yyyy, +mm - 1, +dd);
};

/**
 * Функция обрезки текста
 * @param text - строка, которую нужно сократить
 * @param n - количество видимых символов
 * @param symbol - символ, который нужно поставить в конце строки
 */
export const getShortString = (text: string, n = 50, symbol = '...') => {
    return n > text.length ? text : `${text.slice(0, n)}${symbol}`;
};

/** Заменить подстроку по индексу */
export const replaceAt = (str: string, index: number, replacement: string): string => {
    return str.substr(0, index) + replacement + str.substr(index + replacement.length);
};

/** Общий класс для цветового оформления */
export const variantClass: Record<string, string> = {
    base: 'rf--base',
    accent: 'rf--accent',
    complement: 'rf--complement',
    info: 'rf--info',
    success: 'rf--success',
    danger: 'rf--danger',
    warning: 'rf--warning',
};

export const sizeClass: Record<Size, string> = {
    xxxxs: 'rf--xxxxs',
    xxxs: 'rf--xxxs',
    xxs: 'rf--xxs',
    xs: 'rf--xs',
    s: 'rf--s',
    m: 'rf--m',
    l: 'rf--l',
    xl: 'rf--xl',
    xxl: 'rf--xxl',
    xxxl: 'rf--xxxl',
    xxxxl: 'rf--xxxxl',
};

export const iconSize: Record<Size, string> = {
    xxxxs: '12',
    xxxs: '16',
    xxs: '20',
    xs: '24',
    s: '32',
    m: '40',
    l: '48',
    xl: '56',
    xxl: '64',
    xxxl: '72',
    xxxxl: '80',
};

function oDataServ(data: any) {
    data.results && (data = data.results);

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            key === '__metadata' && delete data[key];

            if (Array.isArray(data[key])) {
                data[key].forEach((item: any) => {
                    oDataServ(item);
                });
            } else if (typeof data[key] === 'object') {
                data[key] = oDataServ(data[key]);
            }
        }
    }

    return data;
}

export const oDataTransform = <T>(): MonoTypeOperatorFunction<T> =>
    map((data: any) => {
        delete data['@odata.context'];
        delete data['@odata.metadataEtag'];
        return data.d ? (oDataServ(data.d) as T) : (data as T);
    });

/** Функция для добавления пробелов в число */
export const numberWithSpaces = (x: number, n = 3, s = ' '): string => {
    const parts = x.toString().split('.');
    const regex = new RegExp(`\\B(?=(\\d{${n}})+(?!\\d))`, 'g');
    parts[0] = parts[0].replace(regex, s);
    return parts.join('.');
};

export const UTCToLocal = (date: Date | number): Date => {
    const offset = -new Date().getTimezoneOffset();
    const withOffset = new Date(date).getTime() + offset * 60000;
    return new Date(withOffset);
};

export const LocalToUTC = (date: Date | number): Date => {
    const offset = -new Date().getTimezoneOffset();
    const withOffset = new Date(date).getTime() - offset * 60000;
    return new Date(withOffset);
};

/** Выделить текст из HTML */
export const extractTextFromHTML = (element: string): string => {
    let result = '';
    let skip = false;

    for (let i = 0; i < element.length; i++) {
        if (element[i] === '<') {
            skip = true;
        }

        if (element[i] === '>') {
            skip = false;
            continue;
        }

        if (skip) {
            continue;
        }

        result += element[i];
    }

    return result;
};

/** Debounce */
export function debounce(fn: (...args: any) => any, ms: number) {
    let timeout: any;
    return function (...args: any) {
        // @ts-ignore
        const fnCall = () => fn.apply(this, args);
        clearTimeout(timeout);
        timeout = setTimeout(fnCall, ms);
    };
}

/** Сегодня приведенное к 00:00:00 */
export const today = (utc = true): number => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return utc ? UTCToLocal(date).getTime() : date.getTime();
};

export const getActivityIdFromCompositeId = (compositeId: string): string => {
    let parts = compositeId.split('_');
    let activityId = parts[parts.length - 1];
    return activityId;
};

export const getDocIdFromLinkedDocId = (linkedDocId: string): string => {
    let parts = linkedDocId.split('/');
    if (parts.length === 1) return linkedDocId;
    if (parts.length === 2) return parts[1];

    throw new Error(`Не удалось распарсить ид документа и родители линка ${parts}`);
};

export const getParentDocIdFromLinkedDocId = (linkedDocId: string): string => {
    let parts = linkedDocId.split('/');
    if (parts.length === 1) return linkedDocId;
    if (parts.length === 2) return parts[0];

    throw new Error(`Не удалось распарсить ид документа и родители линка ${parts}`);
};

export const getAcceptExtensionString = (extensions: string) => {
    if (!extensions.trim()) return '*';
    return extensions
        .split(',')
        .map((ext) => (ext.startsWith('.') ? ext.trim() : `.${ext.trim()}`))
        .join(',');
};

export const groupByToArray = function (list: any, keyGetter: any) {
    const map = new Map();
    list.forEach((item: any) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });

    return Array.from(map, function (item) {
        return { key: item[0], value: item[1] };
    });
};

export const groupByToMap = function (list: any, keyGetter: any) {
    const map = new Map();
    list.forEach((item: any) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });

    return map;
};

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
export const simulateMouseClick = function (element: HTMLElement) {
    mouseClickEvents.forEach((mouseEventType) =>
        element.dispatchEvent(
            new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1,
            }),
        ),
    );
};

/**
 * Вернуть строковое значение из числа или строки.
 *
 * @param key Значение ключа
 */
export const stringifyKey = (key: string | number) => {
    return `${key}`;
};

export const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Б';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'ЭБ', 'ЗБ', 'ИБ'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const checkFilesSize = (files: File[], maxSize: number | undefined): boolean => {
    if (!maxSize) return true;

    if (!files || files.length === 0) return true;

    return (
        files.reduce((sizeSum, file) => {
            return sizeSum + (file ? file.size : 0);
        }, 0) <= maxSize
    );
};

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export const hashCode = (str: string): number => {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

export const getReturnUrl = () => {
    // достаем предыдущий локейшн, затем сохраняем его в стейте auth.returnUrl
    // навигация на этот локейнш осуществляется после логина в AppRouter
    const prevState = history.location.state as {
        fromPathname: string;
        fromSearch: string;
        fromHash: string;
    };
    let returnUrl: string | null = null;
    if (prevState && prevState.fromPathname && prevState.fromSearch && prevState.fromHash) {
        returnUrl = `${prevState.fromPathname}${prevState.fromSearch}${prevState.fromHash}`;
    }
    return returnUrl;
};

export const autoLinkText = (text?: string): string | undefined => {
    const delimiter =
        /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;

    return text
        ?.split(delimiter)
        .map((word, index) => {
            const match = word.match(delimiter);
            if (match) {
                const url = match[0];
                return `<a href=${url.startsWith('http') ? url : 'http://' + url}>${url}</a>`;
            }

            return word;
        })
        .join('');
};

export const formatPrice = (price: number | null | undefined, currencyCode: string): string => {
    try {
        if (price === null || price === undefined) return '';

        return price.toLocaleString('ru-RU', {
            style: 'currency',
            currency: currencyCode?.trim(),
        });
    } catch (ex) {
        return `${price?.toString() ?? ''} ${currencyCode || ''}`;
    }
};

export function declensionOfSymbol(count: number) {
    const cases = [2, 0, 1, 1, 1, 2];
    const titles = ['символ', 'символа', 'символов'];
    return titles[count % 100 > 4 && count % 100 < 20 ? 2 : cases[count % 10 < 5 ? count % 10 : 5]];
}

export const capitalize = (s: string) => s && String(s[0]).toUpperCase() + String(s).slice(1);
