import React, { FC, useRef } from 'react';
import './DocumentTabAttach.scss';

import { TableTab } from '@models/document/IDocumentScheme';
import { UseFormReturn } from 'react-hook-form';
import { IDocumentData } from '@models/document/IDocumentData';
import { IFieldElem } from '@models/IFormData';
import DocumentTabAttachTable from './DocumentTabAttachTable';
import { DropdownContainerContext } from '@/context/Contexts';

export interface IDocumentTabAttachProps {
    /** ИД документа */
    docId: string;
    /** Таблицы */
    tables: TableTab[];
    formMethods: UseFormReturn<IDocumentData>;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
}

const DocumentTabAttach: FC<IDocumentTabAttachProps> = ({
    docId,
    tables,
    formMethods,
    isEdit,
    isNew,
    fields,
}: IDocumentTabAttachProps) => {
    const tabRef = useRef<HTMLDivElement>(null);
    return (
        <DropdownContainerContext.Provider value={tabRef}>
            <div className="attach-tab" ref={tabRef}>
                {tables.map((element) => {
                    return (
                        <DocumentTabAttachTable
                            key={element.key}
                            attachTable={element}
                            formMethods={formMethods}
                            isEdit={isEdit}
                            isNew={isNew}
                            fields={fields}
                            docId={docId}
                        />
                    );
                })}
            </div>
        </DropdownContainerContext.Provider>
    );
};

export default DocumentTabAttach;
