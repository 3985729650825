import './WinnerSelectionTableKO.scss';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ICustomComponentProps } from '@molecules/formbuilder/controls/CustomComponent/CustomComponent';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { Column, DataGrid } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { WinnerSelectionService } from '@services/WinnerSelectionService';
import { IOffer, IOfferAction, OfferActionTypes } from '@models/winnerSelection/ITableDemand';
import { classnames } from '@utils/classnames';
import Preloader from '@atoms/Preloader';
import { MdDownload, MdOutlineExpandMore, MdOutlineFileOpen, MdOutlineFindInPage } from 'react-icons/md';
import Menu from '@atoms/Menu';
import { FilesService } from '@services/FilesService';
import OfferCustomForm from '../OfferCustomForm';
import { IListElement } from '@/types';
import Hint from '@atoms/Hint';
import { ITableKOItem, ITableKOSettings } from '@models/winnerSelection/ITableKO';
import OfferKOCritDetails from '../OfferKOCritDetails';
import { LoadIndicator } from 'devextreme-react';

export interface IWinnerSelectionTableKOProps<TFieldValues extends object = object>
    extends ICustomComponentProps<TFieldValues> {}

const WinnerSelectionTableKO = <TFieldValues extends object = object>({
    component,
    formMethods,
    isEdit,
    isNew,
    fields,
    docId,
    ...props
}: IWinnerSelectionTableKOProps<TFieldValues>) => {
    const activated = React.useRef(false);
    const gridRef = useRef<DataGrid>(null);

    const [items, setItems] = useState<ITableKOItem[]>();
    const [offers, setOffers] = useState<IOffer[]>();
    const [settings, setSettings] = useState<ITableKOSettings>();
    const [offerToEdit, setOfferToEdit] = useState<IOffer>();
    const [showCustomFormModal, setShowCustomFormModal] = useState<boolean>(false);
    const [currentAction, setCurrentAction] = useState<IOfferAction>();
    const [errorText, setErrorText] = useState<string>();

    const tableKey = component.params?.tableKey;

    const loadData = useCallback(() => {
        gridRef.current?.instance.beginCustomLoading('Загрузка');

        if (!tableKey) {
            console.error('WinnerSelectionTableKO. Ошибка загрузки данных (tableKey пустой)');
            return;
        }

        WinnerSelectionService.fetchTableKOData(docId, tableKey)
            .then((response) => {
                setOffers(response.data.offers);
                setItems(response.data.data);
                setSettings(response.data.settings);
            })
            .catch((reason) => {
                setErrorText(reason);
            })
            .finally(() => {
                gridRef.current?.instance.endCustomLoading();
            });
    }, [docId, tableKey]);

    useEffect(() => {
        activated.current = true;

        loadData();

        return () => {
            activated.current = false;
        };
    }, []);

    const gridStore = useMemo(() => {
        return new DataSource({
            store: new ArrayStore({
                key: 'critId',
                data: items,
            }),
        });
    }, [items]);

    const headerMenuLabel = (text: string, icon: ReactNode) => {
        return (
            <div>
                <div className="offer-header-menu__item">
                    {icon}
                    <span>{text}</span>
                </div>
            </div>
        );
    };

    return (
        <div className="winnerSelectionTableKO-wrapper">
            {errorText ? (
                <Hint icon="info" title={`Ошибка загрузки: ${errorText}`} variant="red" maxWidth="100%" />
            ) : null}
            {items !== undefined && offers !== undefined ? (
                offers.length === 0 ? (
                    <div className="winnerSelectionTableKO__empty">
                        <h1>{settings?.emptyTextHeader}</h1>
                        <p>{settings?.emptyText}</p>
                    </div>
                ) : (
                    <>
                        <DevExpressDataGrid
                            id="winnerSelectionTableKOGrid"
                            ref={gridRef}
                            dataSource={gridStore}
                            remoteOperations={true}
                            allowColumnResizing={true}
                            wordWrapEnabled={true}
                            hoverStateEnabled={true}
                            scrolling={{ useNative: false, scrollByContent: true }}
                            columnResizingMode="widget"
                        >
                            <LoadIndicator visible={true} />
                            <Column
                                dataField="critName"
                                caption=""
                                allowEditing={false}
                                width={'300px'}
                                minWidth={200}
                                fixed={true}
                                fixedPosition={'left'}
                            />
                            {offers?.map((offer, index) => {
                                return (
                                    <Column
                                        key={offer.key}
                                        dataField={`offers[${offer.key}]`}
                                        allowSorting={false}
                                        allowEditing={false}
                                        minWidth={330}
                                        width={'400px'}
                                        headerCellRender={(p) => {
                                            const headerMenuItems = offer.actions
                                                .map((action) => {
                                                    switch (OfferActionTypes[action.type]) {
                                                        case OfferActionTypes.CustomForm:
                                                            if (!action.visible) return null;

                                                            return {
                                                                label: headerMenuLabel(
                                                                    action?.displayName ?? 'Просмотреть предложение',
                                                                    <MdOutlineFindInPage size="24px" />,
                                                                ),
                                                                handler: () => {
                                                                    setOfferToEdit(offer);
                                                                    setCurrentAction(action);
                                                                    setShowCustomFormModal(true);
                                                                },
                                                            };

                                                        case OfferActionTypes.DownloadAttachments:
                                                            if (!action.visible) return null;

                                                            return {
                                                                label: headerMenuLabel(
                                                                    action.displayName ?? 'Скачать документацию',
                                                                    <MdDownload size="24px" />,
                                                                ),
                                                                handler: () => {
                                                                    const filesService = new FilesService();
                                                                    filesService.downloadFile(
                                                                        `winnerSelection/downloadAttachments/${offer.key}/${tableKey}/${action.key}`,
                                                                        {
                                                                            title: 'Скачать документацию',
                                                                            message:
                                                                                'Выбранное предложение не содержит документацию.',
                                                                            variant: 'default',
                                                                        },
                                                                    );
                                                                },
                                                            };

                                                        case OfferActionTypes.OpenOffer:
                                                            return {
                                                                label: headerMenuLabel(
                                                                    action?.displayName ?? 'Открыть предложение',
                                                                    <MdOutlineFileOpen size="24px" />,
                                                                ),
                                                                handler: () => {
                                                                    window.open(`./document/${offer.key}`, '_blank');
                                                                },
                                                                disabled: !action.visible,
                                                            };
                                                    }
                                                })
                                                .filter((item) => item !== null) as IListElement[];

                                            return (
                                                <Menu list={headerMenuItems} position="bottom-end">
                                                    <div
                                                        className={classnames({
                                                            'offer-cell-header': true,
                                                        })}
                                                    >
                                                        <div className="offer-cell-header__name">
                                                            <p className="offer-cell-header__title">{offer.name}</p>
                                                        </div>
                                                        <div className="offer-cell-header__menu-button">
                                                            <MdOutlineExpandMore size={'24px'} />
                                                        </div>
                                                    </div>
                                                </Menu>
                                            );
                                        }}
                                        cellRender={(p) => {
                                            return (
                                                <div
                                                    className={classnames({
                                                        'offer-cell': true,
                                                        'offer-cell--allowed': p.data.offers[offer.key].result === 1,
                                                        'offer-cell--not-allowed':
                                                            p.data.offers[offer.key].result === 0,
                                                    })}
                                                >
                                                    <div className="offer-cell__description">
                                                        <div className="offer-cell__field offer-cell__field-description">
                                                            <div className="offer-cell__field-value">
                                                                {p.data.offers[offer.key].result === 1
                                                                    ? 'Допущен'
                                                                    : p.data.offers[offer.key].result === 0
                                                                      ? 'Не допущен'
                                                                      : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="offer-cell__details">
                                                        <OfferKOCritDetails
                                                            tableKey={tableKey!}
                                                            isEdit={p.data.offers[offer.key].allowEdit ?? false}
                                                            disabledHint={settings?.offerCritDisabledHint}
                                                            editButtonName={settings?.editOfferCritButtonName}
                                                            offerId={offer.key}
                                                            demandId={docId}
                                                            value={p.value}
                                                            rowData={p.data}
                                                            onSubmit={() => {
                                                                loadData();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    />
                                );
                            })}
                        </DevExpressDataGrid>
                        {offerToEdit && showCustomFormModal && currentAction ? (
                            <OfferCustomForm
                                tableKey={tableKey!}
                                value={offerToEdit}
                                action={currentAction}
                                demandId={docId}
                                onClose={() => {
                                    setOfferToEdit(undefined);
                                    setCurrentAction(undefined);
                                    setShowCustomFormModal(false);
                                }}
                            />
                        ) : null}
                    </>
                )
            ) : (
                <div className={'winnerSelectionTableKO__loading'}>
                    <Preloader inline={true} size="l" />
                </div>
            )}
        </div>
    );
};

export default WinnerSelectionTableKO;
