import DataGrid, { Column, FilterRow, Paging, Scrolling, Selection, Sorting } from 'devextreme-react/data-grid';
import React, { FC, RefObject, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import './ListModeControl.scss';
import { DictionariesService } from '@services/DictionariesService';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import { IDictFilter } from '@models/Forms/IForms';
import { ColumnFixing } from 'devextreme-react/tree-list';
import { simulateMouseClick } from '@utils/helpers';
import { IDictSettings } from '@atoms/Dictpicker/IDictSettings';
import DataSource from 'devextreme/data/data_source';
import {
    checkGridRowsByScript,
    filterGridRowsByScript,
    filterResponseVoid,
    getColumnDataTypeByFieldDataType,
    getColumnFilterOperationsByColumnDataType,
    getLoadOptionsQuery,
    onCellHoverChanged,
} from '@utils/dataGridUtils';
import { IDictpickerRefActions } from '@atoms/Dictpicker/Dictpicker';
import { classnames } from '@/utils/classnames';
import { ValueType } from '@/types/ValueType';
import { sendErrorMsg } from '@/components/molecules/Errors';

export interface IListModeControlProp extends IDictSettings {
    onSelectedRowItems: (value: IDictionaryData[]) => void;
    onSelectedKeys: (value: string[]) => void;
    dictAttributes: string[];
    filterResponse: IDictFilter;
    controlRef: RefObject<IDictpickerRefActions>;
}

const ListModeControl: FC<IListModeControlProp> = (p: IListModeControlProp) => {
    const [filterRemoteQuery, setFilterRemoteQuery] = useState<any[]>([]);
    //const [selectedItems, setSelectedItems] = useState<string[]>(p.selected);
    useImperativeHandle(p.controlRef, () => ({
        reset: () => {
            gridRef?.current?.instance.refresh();
        },
        setSelected: (items: string[]) => {
            gridRef?.current?.instance.selectRows(items, false);
            gridRef?.current?.instance.refresh();
        },
    }));

    /**
     * TODO Построение фильтрующего условия для отправки на сервер
     * */
    useEffect(() => {
        if (p.filterResponse && p.filterResponse.filter && p.filterResponse.filter != '') {
            var filter = JSON.parse(p.filterResponse.filter.replace(/\'/g, '"'));

            let conditions: any[] = [];
            if (filter.codes) {
                let column = filter.columnName ? filter.columnName : 'code';
                let codes = filter.codes as string[];
                /**
                 * TODO Научить сервер обрабатывать запросы вида ["!", ["value", "=", 3]]
                 * */
                let operand = filter.isShow ? '=' : '!=';
                let binary = filter.isShow ? 'or' : 'and';
                codes.forEach((code) => {
                    if (conditions.length > 0) {
                        conditions.push(binary);
                    }
                    conditions.push([column, operand, code]);
                });
            }
            setFilterRemoteQuery(conditions);
        }
    }, [p.filterResponse]);

    const getUrlParam = (loadOptions: any) => {
        let params = '?';
        params += getLoadOptionsQuery(loadOptions);
        return params;
    };

    const gridSource = useMemo(() => {
        return new DataSource<IDictionaryData, string>({
            /**
             * TODO Перенести фильтрацию из метода load в пост-обработку (корректно работают внутренние счётчики)
             * */
            //postProcess: postProcessFunction,

            filter: p.isFormData || filterRemoteQuery.length == 0 ? null : filterRemoteQuery,
            requireTotalCount: true,
            key: 'code',
            async load(loadOptions: any) {
                let params = getUrlParam(loadOptions);
                params += '&formValues=' + (await p.getFormValuesAsync());
                if (p.predicatesCache) {
                    params += '&predicates=' + p.predicatesCache;
                }
                if (p.isFormData) {
                    return DictionariesService.getGridFormdataItems(p.docId!, p.dictName, params).then((response) => {
                        validateLoadedData(response.data.data);
                        if (
                            p.filterResponse &&
                            (p.filterResponse.script || (p.filterResponse.filter && p.filterResponse.filter != ''))
                        ) {
                            let _data: IDictionaryData[] = [];
                            /**
                             * TODO Добавить серверную фильтрацию для псевдосправочников
                             * */
                            _data = filterResponseVoid(response.data.data, p.filterResponse);
                            if (p.filterResponse?.script) {
                                _data = filterGridRowsByScript(_data, p.filterResponse?.script);
                            }
                            response.data.data = _data;
                            response.data.totalCount = _data.length;
                        }
                        let exDs = p.getExternalDataSource();
                        if (exDs.length > 0) {
                            let data: IDictionaryData[] = [];
                            for (let index = 0; index < response.data.data.length; index++) {
                                const element = response.data.data[index];
                                if (
                                    exDs.findIndex((x) => {
                                        return x.code == element.code;
                                    }) == -1
                                ) {
                                    data.push(element);
                                }
                            }
                            exDs.forEach((element) => {
                                data.push(element);
                            });
                            response.data.totalCount = data.length;
                            response.data.data = data;
                        }

                        return response.data;
                    });
                } else {
                    if (p.joinedDictionaries && p.joinedDictionaries.length > 0) {
                        let joinDictsParams = JSON.stringify(p.joinedDictionaries);
                        params += `&joinedDictionaries=${joinDictsParams}`;
                    }

                    return DictionariesService.getGridItems(p.dictName, params).then((response) => {
                        validateLoadedData(response.data.data);
                        if (p.filterResponse) {
                            let _data: IDictionaryData[] = response.data.data;
                            /**
                             * Используется серверная фильтрация
                             * */
                            //_data = filterResponseVoid(response.data.data, filterResponse);
                            if (p.filterResponse?.script) {
                                _data = filterGridRowsByScript(_data, p.filterResponse?.script);
                            }
                            response.data.totalCount =
                                response.data.totalCount - (response.data.data.length - _data.length);
                            response.data.data = _data;
                        }
                        return response.data;
                    });
                }
            },
        });
    }, [p.dictName, p.filterResponse, filterRemoteQuery]);

    const checkIsRowSelectable = (data?: IDictionaryData) => {
        if (p.selectableLevels) {
            if (p.selectableLevels === 'last') {
                return !data?.hasChild;
            }
            if (p.selectableLevels === 'first') {
                return data?.parent === '';
            }
        }
        return true;
    };

    const validateLoadedData = (data: IDictionaryData[]) => {
        if (
            p.loadedDataValidators &&
            p.loadedDataValidators.validators &&
            p.loadedDataValidators.validators.length > 0
        ) {
            let messages: string[] = [];

            p.loadedDataValidators.validators.forEach((validator) => {
                if (!checkGridRowsByScript(data, validator.script)) {
                    messages.push(validator.message);
                }
            });

            if (messages.length > 0) {
                sendErrorMsg({
                    message: messages,
                });
            }
        }
    };

    const onGridEditorPreparing = (e: any) => {
        if (e.parentType === 'filterRow') {
            e.editorOptions.onEnterKey = function () {
                // применение фильтра по нажатию Enter
                simulateMouseClick(e.element.querySelector('.dx-apply-button')!);
            };
        }
    };

    const onSelectionChanged = (e: any) => {
        let property = 'code';
        if (!p.isMultiple) {
            if (e.currentSelectedRowKeys.length > 0) {
                let item = e.selectedRowsData.pop();
                e.component.selectRows([item[property]], false);
                p.onSelectedKeys([item[property]]);
                p.onSelectedRowItems([item!]);
            } else if (e.selectedRowKeys.length == 0) {
                p.onSelectedKeys([]);
                p.onSelectedRowItems([]);
            }
        } else {
            p.onSelectedKeys(e.selectedRowKeys);
            p.onSelectedRowItems(e.selectedRowsData);
        }
    };

    const gridRef = useRef<DataGrid>(null);

    const getCodeColumn = () => {
        let attr = p.gridAttribute?.attrs.find((a) => {
            return a.key === 'code';
        });
        if (attr) {
            return (
                <Column
                    key={'code'}
                    dataField={'code'}
                    caption={attr.name}
                    width={attr.width}
                    defaultSortOrder={attr.sortOrder}
                />
            );
        }
        if (p.gridAttribute == undefined) {
            return <Column key={'code'} dataField={'code'} caption="Код" width="auto" />;
        }
        return <></>;
    };

    const getSchemeColumns = (dictAttributes: string[]) => {
        return dictAttributes.map((schemeColumn, _) => {
            const index = p.dictAttributes.indexOf(schemeColumn);
            const attr = p.gridAttribute?.attrs.find((a) => a.key === schemeColumn);
            if (attr !== undefined) {
                return (
                    <Column
                        key={index}
                        allowFiltering={true}
                        caption={attr?.name}
                        dataField={`fields[${index}].value`}
                        dataType={attr?.type ? attr?.type : 'string'}
                        width={attr?.width}
                        visible={true}
                        allowSorting={true}
                        defaultSortOrder={attr.sortOrder}
                        filterOperations={getColumnFilterOperationsByColumnDataType(
                            getColumnDataTypeByFieldDataType((attr?.type ? attr?.type : 'string') as ValueType),
                        )}
                        encodeHtml={true}
                        trueText={attr?.type && attr?.type === 'boolean' ? '1' : undefined}
                        falseText={attr?.type && attr?.type === 'boolean' ? '0' : undefined}
                        showEditorAlways={attr?.type && attr?.type === 'boolean' ? true : undefined}
                        //calculateCellValue={attr?.type && attr?.type === 'boolean' ? calcBoolCell : undefined}
                        cellRender={attr?.type && attr?.type !== 'boolean' ? undefined : cellUrlRender}
                        headerCellRender={(p) => {
                            return (
                                <div className="title-column-box">
                                    <div
                                        className={classnames(
                                            'title-column-caption',
                                            attr?.headerNoEllipsis && 'title-column-caption-noEllipsis',
                                        )}
                                    >
                                        {p.column.caption}
                                    </div>
                                </div>
                            );
                        }}
                    />
                );
            }
            if (p.gridAttribute === undefined) {
                return (
                    <Column
                        key={index}
                        allowFiltering={true}
                        caption={schemeColumn}
                        dataField={`fields[${index}].value`}
                        dataType={'string'}
                        visible={true}
                        allowSorting={true}
                        filterOperations={['contains']}
                        encodeHtml={true}
                        cellRender={cellUrlRender}
                    />
                );
            }
            return <React.Fragment key={index}></React.Fragment>;
        });
    };

    const getWidth = () => {
        let w = 100;
        p.gridAttribute?.attrs.forEach((x) => {
            w = w + x.width;
        });
        return w;
    };

    const cellUrlRender = (param: any) => {
        if (p.isFormData && param.data.url && param.data.urlAttributes.indexOf(param.column.caption) !== -1) {
            return (
                <a target="_blank" href={param.data.url}>
                    {param.value}
                </a>
            );
        } else {
            return <>{param.value}</>;
        }
    };

    const calcBoolCell = (rowData: any) => {
        return rowData.condition === '1';
    };

    const onRowClick = (e: any) => {
        if (checkIsRowSelectable(e.data)) {
            if (e.component.getSelectedRowKeys().indexOf(e.key) > -1) {
                e.component.deselectRows([e.key]);
            } else {
                e.component.selectRows([e.key], true);
            }
        }
    };

    const isPaginationEnabled = !(p.loadMode && p.loadMode === 'all');

    // сортируем столбцы в порядке как они заданы в шаблоне
    const gridAttributesKeys = p.gridAttribute?.attrs.map((val) => val.key) ?? [];
    const sortedDictAttributes = [...p.dictAttributes]?.sort(
        (a, b) =>
            (gridAttributesKeys.indexOf(a) === -1 ? Infinity : gridAttributesKeys.indexOf(a)) -
            (gridAttributesKeys.indexOf(b) === -1 ? Infinity : gridAttributesKeys.indexOf(b)),
    );

    if (sortedDictAttributes) {
        return (
            <DevExpressDataGrid
                width={getWidth}
                key={'grid'}
                dataSource={gridSource}
                hoverStateEnabled={true}
                columnHidingEnabled={false}
                columnMinWidth={30}
                showColumnHeaders={true}
                columnAutoWidth={false}
                allowColumnReordering={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
                noDataText={'Нет строк'}
                ref={gridRef}
                onSelectionChanged={onSelectionChanged}
                onEditorPreparing={onGridEditorPreparing}
                defaultSelectedRowKeys={p.selected}
                remoteOperations={!(p.useClientSideDataProcessing ?? false)}
                onRowClick={onRowClick}
                onCellHoverChanged={onCellHoverChanged}
            >
                <Selection mode="multiple" allowSelectAll={p.isMultiple} selectAllMode="allPages" />
                {getCodeColumn()}
                {getSchemeColumns(sortedDictAttributes)}
                <FilterRow showOperationChooser={true} visible={true} applyFilter={'onClick'} />
                <ColumnFixing enabled={true} />
                <Sorting mode="multiple" showSortIndexes={true} />
                {isPaginationEnabled && <Scrolling mode="virtual" />}
                <Paging enabled={isPaginationEnabled} defaultPageSize={10} />
            </DevExpressDataGrid>
        );
    }

    return <></>;
};

export default ListModeControl;
