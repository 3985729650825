import React, { FC } from 'react';
import { MdError, MdWarning } from 'react-icons/md';
import { classnames } from '@utils/classnames';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { IErrorMessage } from '@molecules/Errors/Errors';
import './ErrorModal.scss';

export interface IErrorModalProp {
    /** Данные уведомление */
    item: IErrorMessage;
    /** Закрыть уведомление */
    remove?: (id: number) => void;
}

const ErrorModal: FC<IErrorModalProp> = ({ item, remove }: IErrorModalProp) => {
    const onRemove = () => {
        item.id && remove && remove(item.id);
    };
    const onClose = () => {
        item.closeBtnClick && item.closeBtnClick();
        onRemove();
    };

    const ModalFooter = ({ onClose }: { onClose: () => void }) => (
        <div className={'errorModal_footer'}>
            <div className={'left'}>
                {item.buttons &&
                    item.buttons.map((button, i) => {
                        return (
                            <Button
                                onClick={() => {
                                    onRemove();
                                    button.onClick();
                                }}
                                key={i}
                                size="s"
                            >
                                {button.title}
                            </Button>
                        );
                    })}

                <Button onClick={onClose} size="s">
                    {item.closeBtnTitle ? item.closeBtnTitle : 'Ок'}
                </Button>
            </div>
        </div>
    );

    return (
        <Modal
            size="xl"
            footer={<ModalFooter onClose={onClose} />}
            header={
                <div className={classnames('box')}>
                    <div>
                        <div>{item.title ? item.title : 'Внимание'}</div>
                    </div>
                </div>
            }
        >
            <div className="error-container">
                {item?.message?.length > 0 && Array.isArray(item?.message) ? (
                    item?.message?.map((msg, index) => {
                        return (
                            <div key={index} className="error-Row">
                                <div
                                    className={classnames(
                                        'error-Icon',
                                        item.type === undefined || item.type === 'error'
                                            ? 'error-color'
                                            : 'warning-color',
                                    )}
                                >
                                    {item.type === undefined || item.type === 'error' ? (
                                        <MdError size="24" />
                                    ) : (
                                        <MdWarning size="24" />
                                    )}
                                </div>
                                <div className="error-Msg" dangerouslySetInnerHTML={{ __html: msg?.toString() }}></div>
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
            </div>
        </Modal>
    );
};

export default ErrorModal;
