import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { ProcessActionsExecFactory } from '@utils/actions/ProcessActionsExecFactory';
import { ActivityActionsExecFactory } from '@utils/actions/ActivityActionsExecFactory';
import { IAllRoutesActions } from '@models/actions/IAllRoutesActions';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import { ActivityActionsService } from './ActivityActionsService';
import { ActivityLocationTypes } from './ActivityLocationTypes';
import { IActionService } from '@services/actions/IActionService';

export class ProcessActionsService implements IActionService<IBaseAction> {
    public getActionExecutor(act: IBaseAction): IActionExecutor {
        let result = ProcessActionsExecFactory.GetActionExecutor(act, false);
        if (result !== undefined) return result;
        return ActivityActionsExecFactory.GetActionExecutor(act)!;
    }

    //Получает действия для процесса
    async fetchActions(objId: string): Promise<AxiosResponse<IBaseAction[]>> {
        let ids = objId.split('_');
        let processId = ids[0];
        let actionId = ids[1];

        if (actionId) {
            let actService = new ActivityActionsService(ActivityLocationTypes.DocumentView);
            return actService.fetchActions(actionId);
        } else {
            return $api.get<IBaseAction[]>(`tasks/getActionProcesses/${processId}`);
        }
    }

    //Получает действия для процесса
    async fetchGroupActions(objIds: string): Promise<AxiosResponse<IBaseAction[]>> {
        // групповые действия не поддерживаются
        return new Promise<AxiosResponse<IBaseAction[]>>(() => {});
    }

    //Получает все активити по документу (по всем активити и процессам)
    async fetchActionsDocumentView(docId: string): Promise<AxiosResponse<IAllRoutesActions>> {
        return this.fetchAllRouteActionsByLocation(docId, ActivityLocationTypes.DocumentView);
    }

    //Получает все активити по документу (по всем активити и процессам)
    async fetchAllRouteActionsTitleBar(
        docId: string,
        openDocumentGuid: string | undefined,
    ): Promise<AxiosResponse<IAllRoutesActions>> {
        return this.fetchAllRouteActionsByLocation(docId, ActivityLocationTypes.DocumentTitleBar, openDocumentGuid);
    }

    //Получает все активити по документу (по всем активити и процессам)
    async fetchAllRouteActionsByLocation(
        docId: string,
        location: number,
        openDocumentGuid: string | undefined = undefined,
    ): Promise<AxiosResponse<IAllRoutesActions>> {
        return $api.get<IAllRoutesActions>(`tasks/getAllDocActions/${docId}`, {
            params: {
                location: location,
                openDocumentGuid: openDocumentGuid,
            },
        });
    }

    //Получает все активити по документу (по всем активити и процессам)
    async fetchAllRouteActions(docId: string): Promise<AxiosResponse<IAllRoutesActions>> {
        return $api.get<IAllRoutesActions>(`tasks/getAllDocActions/${docId}`);
    }
}
