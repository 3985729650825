import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from './IActionExecutor';
import { ProcessActionTypes } from '@models/actions/ProcessActionTypes';
import { RemoveFromControlExecutor } from '@utils/actions/processExecutors/RemoveFromControlExecutor';
import { ChangeControllerExecutor } from '@utils/actions/processExecutors/ChangeControllerExecutor';

export class ProcessActionsExecFactory {
    public static allExecutors: { [actType: string]: () => IActionExecutor } = {
        [ProcessActionTypes[ProcessActionTypes.RemoveFromControl]]: () => new RemoveFromControlExecutor(),
        [ProcessActionTypes[ProcessActionTypes.ChangeController]]: () => new ChangeControllerExecutor(),
    };

    static GetActionExecutor(act: IBaseAction, throwError: boolean = true): IActionExecutor | undefined {
        let createExecutor = this.allExecutors[act.type];
        if (!createExecutor) {
            if (!throwError) return undefined;

            throw new Error(`Не обнаружен ActionExecutor для типа ${act.type}`);
        }

        return createExecutor();
    }
}
