import './DocumentModal.scss';
import React, { useEffect, useState } from 'react';
import { classnames } from '@utils/classnames';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Modal from '@atoms/Modal';
import { Controller, useForm } from 'react-hook-form';
import { IDocumentData } from '@models/document/IDocumentData';
import Preloader from '@atoms/Preloader';
import { FormProviderControl, HookFormProvider } from '@controls/index';
import { sendErrorMsg } from '@molecules/Errors';
import DocumentTabBuilder from '@organisms/document/DocumentTabBuilder/DocumentTabBuilder';
import { IField, IFieldElem } from '@models/IFormData';
import { convertListToMap, getValueString } from '@utils/documentUtils';
import { VariablesManager } from '@utils/VariablesManager';
import { useTypedSelector } from '@hooks/useTypedSelector';
import PageWraper from '@atoms/PageWraper';
import { v4 as uuidv4 } from 'uuid';
import DocumentModalTitle from '@organisms/DocumentModal/DocumentModalTitle/DocumentModalTitle';
import { IDocumentScheme } from '@models/document/IDocumentScheme';
import { ValidatorManager } from '@utils/validatorManager';
import { NotificationsManager } from '@utils/notificationsManager';
import { ModalSize } from '@atoms/Modal/Modal';
import { IDocumentModalContext } from '@/types';

export interface IDocumentModalProps {
    /** Схема документа */
    scheme?: IDocumentScheme;
    /** Данные документа */
    data?: IDocumentData;
    /** Загрузка */
    isLoading: boolean;
    /** Режим редактирования */
    isEdit: boolean;
    /** Режим создания нового документа */
    isNew: boolean;
    /** Сохранение документа */
    onSave: (data: IDocumentData) => void;
    /** Закрытие окна */
    onCancel: () => void;
    /** Размер окна */
    modalSize?: ModalSize;
    /** Размер индикатора загрузки */
    preloaderSize?: 's' | 'm' | 'l' | 'xl';
    /** Текст кнопки Сохранить */
    saveButtonText?: string;
    /** Высота во весь экран */
    fullHeight?: boolean;
}

export const DocumentModalContext = React.createContext<IDocumentModalContext>({});

const DocumentModal = ({
    scheme,
    data,
    isLoading,
    isEdit,
    isNew,
    onSave,
    onCancel,
    modalSize = 'max',
    preloaderSize = 'xl',
    saveButtonText = 'Сохранить',
    fullHeight = true,
}: IDocumentModalProps) => {
    const [fieldsData, setFieldsData] = useState<Record<string, IFieldElem>>();
    const [documentTitle, setDocumentTitle] = useState<string>();
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);
    const [idForm, setIdForm] = useState(() => {
        return uuidv4();
    });

    const auth = useTypedSelector((state) => state.auth);

    const methods = useForm<IDocumentData>({
        mode: 'onBlur', // "onChange"
    });

    const initFieldsData = async (list: IField[], creator: string) => {
        let fields = convertListToMap(list);
        fields['doc_Creator'] = {
            name: 'doc_Creator',
            value: creator,
            index: -1,
        };
        if (scheme?.form.view.variables && data) {
            let mng = new VariablesManager(scheme.form.view.variables, data.id, auth.user);
            let variables = await mng.GetData(fields, isEdit, isNew);
            console.log(variables);
            fields['variables'] = {
                name: 'variables',
                value: variables,
                index: -1,
            };
        }

        setFieldsData(fields);
    };

    const getDocumentTitle = () => {
        if (scheme?.isTemplate) {
            setDocumentTitle('Шаблон "' + scheme.templateName + '" (' + scheme.flowTitle + ')');
            return;
        }

        if (fieldsData && fieldsData['variables'] && fieldsData['variables'].value['caption']) {
            setDocumentTitle(fieldsData['variables'].value['caption']);
        } else {
            setDocumentTitle(
                getValueString('|Document|Имя_потока', fieldsData!) +
                    ' ' +
                    getValueString('|Document|Регистрационный_номер', fieldsData!),
            );
        }
    };

    useEffect(() => {
        if (data && scheme) {
            initFieldsData(data.fields, data.doc_Creator);
            setIdForm(uuidv4());
            methods.reset(data);
        }
    }, [data, scheme]);

    useEffect(() => {
        if (fieldsData && scheme) {
            getDocumentTitle();
        }
    }, [fieldsData, isEdit, scheme]);

    const submitData = async (data: IDocumentData) => {
        let errors: string[] = [];
        let notify: string[] = [];
        let validatorManager = new ValidatorManager(fieldsData!, methods);
        let notificationsManager = new NotificationsManager(fieldsData!, methods);
        await validatorManager.validateAllAsync(scheme?.form.view.validators?.validator, errors);
        if (errors.length === 0) {
            await notificationsManager.notificationsAllAsync(scheme?.form.view.notifications?.messages, notify);

            if (notify.length > 0) {
                sendErrorMsg({
                    message: notify,
                    closeBtnClick: () => {
                        onSave(data);
                    },
                    title: scheme?.form.view.notifications?.title,
                    type: 'warning',
                });
            } else {
                onSave(data);
            }
        } else {
            sendErrorMsg({
                message: errors,
            });
        }
    };

    const onSubmit = async (data: IDocumentData) => {
        setSubmitButtonDisabled(true);
        await submitData(data);
        setSubmitButtonDisabled(false);
    };

    const modalHeader = (
        <div className={classnames('document-modal__header')}>
            <div className={classnames('document-modal__header-title')}>
                {fieldsData ? <DocumentModalTitle titleText={documentTitle} fieldsData={fieldsData} /> : null}
            </div>
            <div className={classnames('document-modal__header-close')}>
                <Button
                    buttonType="text"
                    textColor="neutral"
                    size="xs"
                    aria-label="Закрыть окно"
                    onClick={() => {
                        onCancel();
                    }}
                    startAdornment={<MdClose size="24" />}
                />
            </div>
        </div>
    );

    const modalFooter = (
        <div className={classnames('document-modal__footer')}>
            {(isNew || isEdit) && (
                <Button
                    buttonType="primary"
                    className="edit-save-btn"
                    size="s"
                    type="submit"
                    form={idForm}
                    aria-label="Сохранить"
                    disabled={submitButtonDisabled}
                >
                    {saveButtonText}
                </Button>
            )}
        </div>
    );

    return (
        <Modal
            className={classnames('document-modal', fullHeight && 'document-modal--full-height')}
            size={modalSize}
            header={modalHeader}
            footer={modalFooter}
        >
            {isLoading || !fieldsData || !data || !scheme ? (
                <div className="document-modal__busy-loader">
                    <Preloader size={preloaderSize} />
                </div>
            ) : (
                <FormProviderControl
                    formMethods={methods}
                    className="document-modal__form"
                    id={idForm}
                    onSubmit={onSubmit}
                    onInvalid={(errors) => {
                        Object.keys(fieldsData).forEach((key) => {
                            const fieldName = fieldsData[key].name;
                            let curElm: any = errors;
                            const errorField = curElm.fields.find((field: any) => field?.value?.ref.name === fieldName);
                            if (errorField) {
                                console.log(
                                    `Ошибка валидации поля: ${key} (${errorField.value.ref.name}). Подробности ниже.`,
                                );
                                console.log(errorField);
                            }
                        });
                    }}
                >
                    <HookFormProvider controller={Controller}>
                        <PageWraper className="document-modal__wrapper">
                            <DocumentModalContext.Provider value={{ data: data, scheme: scheme }}>
                                <DocumentTabBuilder
                                    docId={data.id}
                                    scheme={scheme}
                                    methods={methods}
                                    setError={(errors?: string[]) => {
                                        if (errors) {
                                            sendErrorMsg({
                                                message: errors,
                                            });
                                        }
                                    }}
                                    fields={fieldsData}
                                    isNew={isNew}
                                    isEdit={isEdit}
                                    useLinks={false}
                                    hideRoutes={true}
                                    hideStatusBar={true}
                                    hideRoutesMap={true}
                                    hideEisLog={true}
                                />
                            </DocumentModalContext.Provider>
                        </PageWraper>
                    </HookFormProvider>
                </FormProviderControl>
            )}
        </Modal>
    );
};

export default DocumentModal;
