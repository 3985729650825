import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from './IActionExecutor';
import { LinkedDocsTypes } from '@models/actions/LinkedDocsTypes';
import { RemoveLinkExecutor } from '@utils/actions/docExecutors/RemoveLink/RemoveLinkExecutor';

export class LinkedDocActionsExecFactory {
    public static allExecutors: { [actType: string]: () => IActionExecutor } = {
        [LinkedDocsTypes[LinkedDocsTypes.RemoveLink]]: () => new RemoveLinkExecutor(),
    };

    static GetActionExecutor(act: IBaseAction, throwError: boolean = true): IActionExecutor | undefined {
        let createExecutor = this.allExecutors[act.type];
        if (!createExecutor) {
            if (!throwError) return undefined;

            throw new Error(`Не обнаружен ActionExecutor для типа ${act.type}`);
        }

        return createExecutor();
    }
}
