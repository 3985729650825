import './GridSettingsButton.scss';
import React, { FC, useMemo, useRef } from 'react';
import Button from '@atoms/Button';
import Menu from '@atoms/Menu';
import { DataGridWordWrapMode, IDataGridSettings } from '@models/dataGrid/IDataGridSettings';
import { MdAddchart, MdMenu, MdRefresh, MdSettings } from 'react-icons/md';
import { IListElement, ISegment } from '@/types';
import Segment from '@atoms/Segment';
import { IMenuRefActions } from '@atoms/Menu/Menu';

export interface IGridSettingsButtonProps {
    value: IDataGridSettings;
    onChange: (value: IDataGridSettings) => void;
    onClickStateChooser: () => void;
    onClickRefresh: () => void;
    onClickColumnChooser: () => void;
}

const wordWrapModeList: ISegment[] = [
    {
        value: `${DataGridWordWrapMode.NoWrap}`,
        label: '1 строка',
    },
    {
        value: `${DataGridWordWrapMode.Trim}`,
        label: '5 строк',
    },
    {
        value: `${DataGridWordWrapMode.Wrap}`,
        label: 'Все',
    },
];

const GridSettingsButton: FC<IGridSettingsButtonProps> = ({
    value,
    onChange,
    onClickStateChooser,
    onClickRefresh,
    onClickColumnChooser,
}: IGridSettingsButtonProps) => {
    const mouseOnComponent = useRef(true);
    const menuRef = useRef<IMenuRefActions>(null);
    const menuList = useMemo<IListElement[]>(() => {
        return [
            {
                label: (
                    <div className="grid-settings__menu-item">
                        <MdMenu size="24" />
                        <div className="grid-settings__menu-item-label">Список состояний</div>
                    </div>
                ),
                separated: false,
                value: '1',
                handler: onClickStateChooser,
            },
            {
                label: (
                    <div className="grid-settings__menu-item">
                        <MdRefresh size="24" />
                        <div className="grid-settings__menu-item-label">Сбросить состояние</div>
                    </div>
                ),
                separated: true,
                value: '2',
                handler: onClickRefresh,
            },
            {
                label: (
                    <div className="grid-settings__menu-item">
                        <MdAddchart size="24" />
                        <div className="grid-settings__menu-item-label">Выбор столбцов</div>
                    </div>
                ),
                separated: true,
                value: '3',
                handler: onClickColumnChooser,
            },
            {
                label: (
                    <div className="grid-settings__item">
                        <div className="grid-settings__item-label">Количество строк текста в ячейке</div>
                        <Segment
                            list={wordWrapModeList}
                            fullWidth={true}
                            value={wordWrapModeList[value.wordWrapMode]}
                            onChange={(e) => {
                                onChange({
                                    ...value,
                                    wordWrapMode: +e.value,
                                });
                            }}
                        />
                    </div>
                ),
                preventClick: true,
                separated: true,
                value: '4',
            },
        ] as IListElement[];
    }, [onChange, onClickColumnChooser, onClickRefresh, onClickStateChooser, value]);

    return (
        <div
            className="grid-settings"
            onMouseEnter={() => {
                mouseOnComponent.current = true;
            }}
            onMouseLeave={() => {
                mouseOnComponent.current = false;
                setTimeout(() => {
                    if (!mouseOnComponent.current) menuRef.current?.close();
                }, 1000);
            }}
        >
            <Menu menuRef={menuRef} list={menuList} position="bottom-end">
                <div className="grid-settings__menu">
                    <Button buttonType="text" textColor="neutral" size="s" startAdornment={<MdSettings size="24" />} />
                </div>
            </Menu>
        </div>
    );
};

export default GridSettingsButton;
